
import { defineComponent } from 'vue';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CIconUpDown',
	props: {
		isUp: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		disabled: {
			required: false,
			type: Boolean,
			default: () => false,
		},
	},
	setup() {
		return {
			faChevronDown,
		};
	},
});
