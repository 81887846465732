
import { defineComponent } from 'vue';
import CCollapse from '@/components/shared/CCollapse.vue';
import { capitalizeWord, getImageFormAssets, range, scrollIntoView, t } from '@/utils/utils';
import { townshipTableLat } from '@/utils/townshipsTable.utils';
import { variablesTable } from '@/utils/variablesTable.utils';
import { CONTACT_MAIL } from '@/utils/constants.utils';
import { QuestionId } from '@/enums/QuestionId.enum';
import { useMeta } from 'vue-meta';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'InfoQAndA',
	components: { CCollapse },
	setup() {
		useMeta({
			title: t('tab.info.qAndA'),
		})
	},
	data() {
		return {
			mail: CONTACT_MAIL,
			getImageFormAssets,
			range,
			capitalizeWord,
			townshipTableLat,
			variablesTable: variablesTable(),
			QuestionId,
		};
	},
	computed: {
		currentQuestionId(): string {
			return this.$route.hash.substring(1);
		},
	},
	mounted() {
		if (this.currentQuestionId) {
			scrollIntoView(`#${this.currentQuestionId}`);
		}
	},
	methods: {
		setQuestionId(id: QuestionId) {
			window.history.pushState({}, null, `${this.$route.path}#${id}`);
		},
		removeQuestionId() {
			window.history.pushState({}, null, this.$route.path);
		},
		handleQuestionToggle(id: QuestionId, isOpen: boolean): void {
			if (isOpen) {
				this.setQuestionId(id);
			} else {
				this.removeQuestionId();
			}
		},
		toOpenQuestion(id: QuestionId): boolean {
			return this.currentQuestionId === id;
		},
	},
});
