import MyLocale from '@/models/MyLocale.model';
import { townshipTableLat } from '@/utils/townshipsTable.utils';

export const en = new MyLocale({
	title: 'Digital Climate Atlas of Serbia',

	nav: {
		home: 'Home',
		map: 'Map',
		about: 'About',
		rawFiles: 'Raw Files',
		contact: 'Contact',
		info: 'FAQ',
	},
	tab: {
		info: {
			qAndA: 'Questions & Answers',
			climateIndexes: 'Climate Indices',
			fileNames: 'File Names',
			citingData: 'Citing data',
		},
	},
	word: {
		for: 'for',
		to: 'to',
		and: 'and',
		result: 'result',
		results: 'results',
		note: 'Note',
		mail: 'e-mail',
		here: 'here',
		netcdf: 'NetCDF',
		ncdump: 'ncdump',
		csv: 'csv',
		csvLong: 'Comma Separated Values',
		spreadsheet: 'spreadsheet',
		lon: 'longitude',
		lat: 'latitude',
		search: 'search',
		send: 'send',
		name: 'name',
		email: 'e-mail',
		address: 'address',
		title: 'title',
		message: 'message',
		definition: 'definition',
		page: 'page',
		days: 'days',
	},
	message: {
		allOptionsAreRequired: 'All options are required',
		requiredOption: 'Required',
		emptyOptions: 'These options are empty',
		requiredFiled: 'Required',
		successfulAction: 'Your action was successful.',
		unsuccessfulAction: 'Something went wrong.',
		unusedLegendItem: 'Unused value',
		exampleOfUsage: 'example of usage',
		developedBy: 'Developed by',
		numberOfEvents: 'number of events',
	},
	filters: {
		dataVisualisation: {
			title: 'Data',
			observation: {
				title: 'Observations',
				info:
					'Review of observed climatic quantities and climatic indices for the period 1950-2020',
			},
			projection: {
				title: 'Future climate projections',
				info:
					'View possible future changes in climate variables and climate indices up to 2100 for\n' +
					'different scenarios.',
			},

			obspro: 'Observations\n(changes)',
			obsvre: 'Observations\n(reference values)',
			modpro: 'Future climate projections (changes)',
			modvre: 'Future climate projections (reference values)',
		},
		variable: {
			title: 'Variables',
			info: 'Only one parameter can be selected',
			infoRawFiles: 'Multiple parameters can be selected',

			baseTitle: 'ESSENTIAL CLIMATE VARIABLES',
			indexTitle: 'Climate indices',

			tas: 'tas',
			tasmax: 'tasmax',
			tasmin: 'tasmin',
			pr: 'pr',
			fd: 'fd',
			id: 'id',
			td: 'td',
			tr: 'tr',
			wsdi: 'wsdi',
			rr20: 'rr20',
			rr30: 'rr30',
			rr50: 'rr50',
			cdd: 'cdd',
			gsl5: 'gsl5',
			gsl10: 'gsl10',
			spei: 'spei',
			htc: 'htc',
			eq: 'eq',
			fai: 'fai',
			hwfi: 'hwfi',
			rx1d: 'rx1d',
			rx5d: 'rx5d',
			su: 'su',
			// new:
			txge35: 'txge35',
			hwfid: 'hwfid',
			hwdi: 'hwdi',
			hwdid: 'hwdid',
			cwd: 'cwd',
			spei6a: 'spei6a',

			tasTooltip: 'Daily average temperature',
			tasmaxTooltip: 'Daily maximum temperature',
			tasminTooltip: 'Daily minimum temperature',
			prTooltip: 'Precipitation',
			fdTooltip: 'Frost days index',
			idTooltip: 'Ice days index',
			tdTooltip: 'Tropical days index',
			trTooltip: 'Tropical nights index',
			wsdiTooltip: 'wsdi',
			rr20Tooltip: 'Number of days with precip. > 20 mm',
			rr30Tooltip: 'Number of days with precip. > 30 mm',
			rr50Tooltip: 'Number of days with precip. > 50 mm',
			cddTooltip: 'Consecutive dry days index',
			gsl5Tooltip: 'Growing season length (10°C)',
			gsl10Tooltip: 'Growing season length (10°C)',
			speiTooltip: 'spei',
			htcTooltip: 'Hydro-thermal Coefficient',
			eqTooltip: "Ellenberg's climate quotient",
			faiTooltip: 'Forestry aridity index',
			hwfiTooltip: 'Heat wave index',
			rx1dTooltip: 'Highest one-day precipitation amount',
			rx5dTooltip: 'Highest five-day precipitation amount',
			suTooltip: 'Summer days index',
			txge35Tooltip: 'Number of days with temperature above 35 ° C',
			hwfidTooltip: 'Heat wavelength',
			hwdiTooltip: 'Number of warm periods',
			hwdidTooltip: 'Length of warm periods',
			cwdTooltip: 'Consecutive rainy days',
			spei6aTooltip: 'SPEI Drought Index',

			tasTitle: 'Daily average temperature (tas)',
			tasmaxTitle: 'Daily maximum temperature (tasmax)',
			tasminTitle: 'Daily minimum temperature (tasmin)',
			prTitle: 'Precipitation (pr)',
			fdTitle: 'Frost days index (fd)',
			idTitle: 'Ice days index (id)',
			tdTitle: 'Tropical days index (td)',
			trTitle: 'Tropical nights index (tr)',
			wsdiTitle: 'wsdi',
			rr20Title: 'Number of days with precip. > 20 mm (rr20)',
			rr30Title: 'Number of days with precip. > 30 mm (rr30)',
			rr50Title: 'Number of days with precip. > 50 mm (rr50)',
			cddTitle: 'Consecutive dry days index (cdd)',
			gsl5Title: 'Growing season length (10°C) (gsl5)',
			gsl10Title: 'Growing season length (10°C) (gsl10)',
			speiTitle: 'spei',
			htcTitle: 'Hydro-thermal Coefficient (htc)',
			eqTitle: "Ellenberg's climate quotient (eq)",
			faiTitle: 'Forestry aridity index (fai)',
			hwfiTitle: 'Heat wave index (hwfi)',
			rx1dTitle: 'Highest one-day precipitation amount (rx1d)',
			rx5dTitle: 'Highest five-day precipitation amount (rx5d)',
			suTitle: 'Summer days index (su)',
			// new:
			txge35Title: 'Number of days with temperature above 35 ° C (txge35)',
			hwfidTitle: 'Heat wavelength (hwfid)',
			hwdiTitle: 'Number of warm periods (hwdi)',
			hwdidTitle: 'Length of warm periods (hwdid)',
			cwdTitle: 'Consecutive rainy days (cwd)',
			spei6aTitle: 'SPEI Drought Index (spei6a)',
		},
		period: {
			title: 'Period',
			info: 'Multiple parameters can be selected',

			base: {
				title: 'Reference period',
				titleShort: 'Base',
				info: '',
			},
			change: {
				title: 'Period',
				titleShort: 'Change',
				info: '',
			},
			scenario: {
				title: 'Scenario',
				titleShort: 'Scenario',
				info: '',

				rcp45: 'mitigation (rcp45)',
				rcp85: 'no mitigation (rcp85)',
				'SRES-A1B': 'SRES-A1B',
			},
		},
		heating: {
			title: 'Heating',
		},
		season: {
			title: 'Season',
			info: 'Only one parameter can be selected',

			god: 'Annual',
			djf: 'Winter (Dec-Jan-Feb)',
			mam: 'Spring (Mar-Apr-May)',
			jja: 'Summer (Jun-Jul-Aug)',
			son: 'Autumn (Sep-Oct-Nov)',

			godSeason: 'Year',
			djfSeason: 'Winter',
			mamSeason: 'Spring',
			jjaSeason: 'Summer',
			sonSeason: 'Autumn',

			godTooltip:
				'Annual\n- january\n- february\n- mart\n- april\n- may\n- jun\n- july\n- avgust\n- september\n- october\n- november\n- december',
			djfTooltip: 'Winter\n- december\n- january\n- february',
			mamTooltip: 'Spring\n- mart\n- april\n- may',
			jjaTooltip: 'Summer\n- jun\n- july\n- avgust',
			sonTooltip: 'Autumn\n- september\n- october\n- november',
		},
		area: {
			title: 'Area',
			info: 'Only one parameter can be selected',

			serbia: 'Serbia',
			regions: 'Regions',
			townships: 'Municipalities',
			point: 'Area ~10x10km',

			serbiaTooltip: '',
			regionsTooltip: '',
			townshipsTooltip: '',
			pointTooltip: '',

			serbiaSummary: 'country',
			regionsSummary: 'region',
			townshipsSummary: 'municipality',
			pointSummary: 'area ~10x10km',

			serbiaSearchPlaceholder: '',
			regionsSearchPlaceholder: 'Search the regions',
			townshipsSearchPlaceholder: 'Search municipalities',
			pointSearchPlaceholder: 'Search for coordinates',
		},
	},
	options: {
		download: {
			image: 'Image',
			pdf: 'PDF',
			file: 'File',
		},
	},
	legend: {
		min: 'min',
		max: 'max',
	},
	mapDetails: {
		tabSeries: 'Time Series',
		tabSummary: 'Table Summary',

		deviation: 'anomaly',
		value: 'value',
		period: 'annual values',

		ens: 'median',
		ens75: '75th percentile',
		ens25: '25th percentile',
		basic: 'individual models',

		chart: {
			forPeriod: 'for period',
			deviationForPeriod: 'anomaly for period',
			relativeTo: 'relative to',
			forScenario: 'and for scenario',
		},
	},
	tour: {
		closeButton: 'Close',
		backButton: 'Back',
		nextButton: 'Next',
		completeButton: 'Complete',
		startButton: 'Start platform tour',

		map: {
			title: 'Welcome to the platform of the digital atlas of climate change in Serbia.',
			info: `
			   This platform will primarily allow you to easily access
         relevant information on the observed climate change over the last few
         decade, but also about possible changes in the future depending on the chosen one
         greenhouse gas emission scenarios.
         <br />
         <br />
         All data on the platform can be downloaded in a digitally readable format, so that
         can be used for various impact studies, vulnerabilities and adaptation planning on
         climate changes.
         <br />
         <br />
         Through this short guide we want to introduce you to the basic ways of navigating and
         downloading data on the platform.`,

			map:
				'The map you see is divided into areas. You can click on each of them. After clicking on a certain area, in the second screen you will see a graph with values and summarized data.',

			observation:
				'In this part of the menu you can choose whether you want to display the views on the map\n' +
				'values of climate variables / indices or observed changes in climate variables / indices\n' +
				'in relation to the corresponding reference period.',

			projection:
				'In this part of the menu you can choose whether you want to display the appropriate ones on the map\n' +
				'values of climate variables / indices for different climate change scenarios or\n' +
				'changes in climate values / indices in the future in relation to the corresponding reference\n' +
				'period of time. The presented changes of the future climate were obtained using the results of the ensemble\n' +
				'regional climate models.',

			variables:
				'Clicking on this field opens the Variables menu' +
				'<br />' +
				'The selected value is also shown.',
			variablesFilter:
				'In this part of the menu you can choose which basic climate size or climate\n' +
				'index you want to display on maps. The basic climatic sizes are: medium daily\n' +
				'temperature, minimum daily temperature, maximum daily temperature and\n' +
				'total daily precipitation. Climate indices calculated from basic quantities\n' +
				'indicate some of the quantitative characteristics of climatic conditions on the appropriate\n' +
				'locations, and their values may be important for assessing the impact of climate on\n' +
				'relevant socio-economic sectors such as agriculture, water resources,\n' +
				'forestry, health, biodiversity, etc.' +
				'<br />' +
				'<br />' +
				'You can find the exact definitions of the index and other information about them in the portal section\n' +
				'About the project',
			period:
				'Clicking on this field opens the Period menu' +
				'<br />' +
				'The selected value is also shown.',
			periodFilter:
				'In this part of the menu you can choose for which time period you want to display\n' +
				'the average value or change in the selected baseline climate variable or index.\n' +
				'If you have chosen to display the value of variables, you will have a choice of multiple references\n' +
				'period of time. If you have chosen to display the change, in addition to the reference period\n' +
				'you will also be able to select the period for which the change will be displayed in relation to\n' +
				'previously selected reference period. Finally, if you have previously selected\n' +
				'projections of the future climate you will have a choice of two scenarios rcp45 and rcp85, which you can choose\n' +
				'one of them.',

			season:
				'Clicking on this field opens the Season menu' +
				'<br />' +
				'The selected value is also shown.',
			seasonFilter:
				'In this part of the menu, in case you have selected the display of basic climate variables,\n' +
				'you can also choose the appropriate season (winter, spring, summer, autumn or all year round). U\n' +
				'in the case of indexes their values are always calculated on an annual basis.',

			area:
				'Clicking on this field opens the Area menu' +
				'<br />' +
				'The selected value is also shown.',
			areaFilter:
				'In this part of the menu, select the spatial level you want the data to be\n' +
				'summarized and subsequently presented as a time series. Possible space levels\n' +
				'data aggregations are Serbia, regions (Administrative districts), municipalities and individual points.\n' +
				'The spatial step of the grid network is approximately 10 x 10 km.',
			areaSearch:
				'If you have selected regions or municipalities, their borders will be displayed on the map. U\n' +
				'In the "Search .." box, you can type the name of the region or municipality of interest (eg Raska) and\n' +
				'the selected area on the map will be shaded. If you click on the selected area at the bottom\n' +
				'portal you will get graphical time series of the corresponding variable.',
			areaSearchLonLat:
				'This form allows you to select a specific area by Latitude and Longitude. If there is no area with a certain Latitude and Longitude, nothing will be selected.',

			legend:
				'Each map is accompanied by a corresponding legend, which will be in the upper left corner. Legend\n' +
				'will help you associate each color on the map with the corresponding value of the selected one\n' +
				'variables.',
			zoomIn: 'With the zoom command, you can interactively focus on a specific part of the map.',
			zoomOut:
				'On the contrary, it shows a larger area of the territory, if only its focus is currently in focus.\n' +
				'smaller part',
			pin:
				'The pin command allows any point within the territory of Serbia to be located on the map,\n' +
				'simply get information about the exact geographical coordinates of the selected point and\n' +
				'the values of the selected variable at that location.',
			download:
				'You will be able to download by clicking on the download button\n' +
				'the data used to draw the map, but also the entire time series\n' +
				'the corresponding variable from which the average value shown on the map is calculated.',
		},
	},
	rawFiles: {
		title: '',
		info: '',

		filters: {
			title: '',
			info: '',

			data: {
				title: 'The Data',
				info: 'Only one parameter can be selected',
			},
			variables: {
				title: 'Variables',
				info: 'Multiple parameters can be selected',
			},
			season: {
				title: 'Time Frequency',
				info: 'Only one parameter can be selected',

				d: 'daily',
				m: 'monthly',
				g: 'annual',
			},
			scenario: {
				title: 'Scenario',
				info: 'Only one parameter can be selected',
			},
		},

		ebupom: 'SNC projections (1951-2100)',
		eobs: 'Observations (obs) (1950-2020)',
		models: 'Projections (mod) (1951-2100)',

		viz: 'values',
		basePeriod: 'for period',

		// scenario
		rcp45: 'scenario RCP4.5',
		rcp85: 'scenario RCP8.5',
		'sres-a1b': 'scenario A1B',

		// model
		eobsFile: 'observations',
		modelensFile: 'climate models projections',
		ebupomFile: 'EBU-POM model projections',

		noResultsTitle: 'No results found',
		noResultsMessage:
			'There are no results for the currently selected values. Choose some other values.',

		applyFilters: 'Apply Filters',
	},
	faq: {
		title: '',
		info: '',

		qAndA: {
			title: 'Questions & Answers',
			info: '',

			// slots
			portalUrl: 'www.climateurope.eu',
			climateTerms: 'Observations',
			climateModels: 'Future climate projections',
			worldMeteorologicalOrganization: 'World Meteorological Organization',
			wmo: 'WMO',
			mostCommonUsed: 'najčešće korišćena',
			periodBase: 'Referent values',
			periodChanges: 'Changes',

			q5AnswerSlot1:
				'CCl/CLIVAR/JCOMM Expert Team (ET) on Climate Change Detection and Indices (ETCCDI)',
			q5AnswerSlot2: 'dnu',

			q6AnswerSlot1: 'E-OBS podaci',
			q6AnswerSlot2: 'ECA&D',

			q7AnswerSlot1: 'EURO-CORDEX',
			q7AnswerSlot2: 'linku',
			q7AnswerSlot3: 'Copernicus Climate Change Service',

			q8AnswerSlot1: 'scenario',
			q8AnswerSlot2: 'projekcijama',

			q9AnswerSlot1: 'prognoziramo',
			q9AnswerSlot2: 'projekciju',
			q9AnswerSlot3:
				'U tom smislu rezultate klimatskih modela ne smemo koristiti i tumačiti kao prognozu vremena. Recimo ako je klimatski model dao da će u budućnosti, 2051. u našoj zemlji biti jaka suša, to ne znači da trebamo zaista očekivati sušu 2051. godini, već da su velike šanse i da možemo očekivati da će se pojaviti neuobičajeno jaka suša u nekom višegodišnjem periodu centriranom oko 2051. godine (recimo periodu od 30 godina)',

			q15AnswerSlot1: 'Sezona',

			q16AnswerSlot1: 'Oblast',

			q18AnswerSlot2: 'INSPIRE direktive',

			question1: {
				question: 'What data are used for the platform development?',
				// slots: observations, here1, projections, here2
				answer:
					'In section {climateTerms}, the background dataset is EOBS gridded climatology. More about EOBS dataset can be found {here1}.\n\n' +
					'In section {climateModels}, the background dataset is EURO-CORDEX bias-adjusted data. More about EURO-CORDEX data can be found {here2}.',
			},

			question2: {
				// slots: climateTerms, climateModels, periodBase, periodChanges
				question:
					'Why there are two subsections, {periodBase} and {periodChanges}, in sections {climateTerms} and {climateModels}?',
				answer:
					'In subsection {periodBase}, the long-term average values over different reference time period in the past can be plotted. In subsection {periodChanges} the anomalies with the respect to the reference values can be plotted.',
			},

			question3: {
				question: 'Why there are different reference periods?',
				// slots: wmo
				answer:
					'There is no common long term reference period. On this platform, several reference periods are used. The reference periods selected for the platform are those used by Republic Hydrometeorological service, reference periods used in Second National communication of the Republic of Serbia, and reference period used in the IPCC AR5. More about reference periods can be found on {wmo}.',
				info: '',
			},

			question4: {
				question: 'What are the essential climate variables and what are the climate indices?',
				answer:
					'An essential climate variable is a physical variable that critically contributes to the characterization of Earth’ s climate. On this platform the essential climate variables are: average daily temperature, minimum daily temperature, maximum daily temperature and daily precipitation.\n\n' +
					'Climate index is a numerical value, often a function of the essential climate variable, used to characterize the climate of a location or region.',
			},

			question5: {
				question: 'What climate indices can be found on the platform?',
				// slots: here
				answer:
					'Majority of the indices on the planform are defend by the Expert Team on Sector-Specific Climate Indices (ET-SCI) and definitions can be found {here}. The full list of the indices can be found below.',
			},

			question6: {
				question:
					'Where I can find more information about gridded climatology (Observations section)?',
				// slots: here2
				answer: 'More about EOBS data set can be found {here2}.',
			},

			question7: {
				question: 'Where I can find more information about climate projections?',
				// slots: here
				answer: 'More about EURO-CORDEX data can be found {hereEng}.',
			},

			question8: {
				question: 'What is emission scenario and what is climate projections?',
				answer:
					'A plausible representation of the future development of emissions of substances that are radiatively active (e.g., greenhouse gases (GHGs), aerosols) based on a coherent and internally consistent set of assumptions about driving forces (such as demographic and socio-economic development, technological change, energy and land use) and their key relationships. Concentration scenarios, derived from emission scenarios, are often used as input to a climate model to compute climate projections. See also Baseline scenario, Mitigation scenario, Socio-economic scenario, Scenario, Representative Concentration Pathways (RCPs) (under Pathways), Shared Socio-economic Pathways (SSPs) (under Pathways) and Transformation pathways (under Pathways)\n\n' +
					'A climate projection is the simulated response of the climate system to a scenario of future emission or concentration o greenhouse gases (GHGs) and aerosols, generally derived using climate models. Climate projections are distinguished from climate predictions by their dependence on the emission/concentration/radiative forcing scenario used, which is in turn based on assumptions concerning, for example, future socioeconomic and technological developments that may or may not be realized.',
			},

			question9: {
				question: 'What is climate model?',
				answer:
					'A numerical representation of the climate system based on the physical, chemical and biological properties of its components, their interactions and feedback processes, and accounting for some of its known properties. The climate system can be represented by models of varying complexity; that is, for any one component or combination of components a spectrum or hierarchy of models can be identified, differing in such aspects as the number of spatial dimensions, the extent to which physical, chemical or biological processes are explicitly represented, or the level at which empirical parametrizations are involved. Climate models are applied as a research tool to study and simulate the climate and for operational purposes, including monthly, seasonal and interannual climate predictions. See also Earth system model (ESM).',
			},

			question10: {
				question: 'What is climate model ensemble?',
				answer:
					'Esemble is a group of parallel model simulations characterising historical climate conditions or climate projections. Variation of the results across the ensemble members may give an estimate of modelling-based uncertainty. Ensembles made with the same model but different initial conditions only characterize the uncertainty associated with internal climate variability, whereas multimodel ensembles including simulations by several models also include the impact of model differences. Perturbed parameter ensembles, in which model parameters are varied in a systemati manner, aim to assess the uncertainty resulting from internal model specifications within a single model. Remaining sources of uncertainty unaddressed with model ensembles are related to systematic model errors or biases, which may be assessed from systematic comparisons of model simulations with observations wherever available. See also Climate projection.',
				images: [''],
			},

			question11: {
				question: 'What is the time period of climate model projections dataset?',
				answer:
					'The time period is from 1951 to 2100. You can use so called historical part of the integration from 1951 to 2006 to compare model results to observed climate condition.',
				images: [''],
			},

			question12: {
				question: 'What is difference between RCP4.5 and RCP8.5 scenarios?',
				// slots: here
				answer:
					'The RCP 4.5 is a stabilization scenario where total radiative forcing is stabilized before 2100 by employment of a range of technologies and strategies for reducing greenhouse gas emissions.\n\n' +
					'The RCP 8.5 is characterized by increasing greenhouse gas emissions over time representative for scenarios in the literature leading to high greenhouse gas concentration levels.\n\n' +
					'More about emissions from different scenarios, and many other information can be found {here}.',
			},

			question13: {
				question: 'What is data spatial resolution?',
				answer: 'The data spatial resolution is about 10 x 10 km.',
			},

			question14: {
				question: 'What is data time resolution?',
				answer:
					'For the essential climate variables there a daily, monthly and annual data. All climate indices have annual time frequency.',
			},

			question15: {
				question: 'Are there seasonal maps?',
				answer:
					'For the essential climate variables there are seasonal maps. The seasons are, winter (December to February), spring (March to May), summer (Jun to August) and fall (September to November).',
			},

			question16: {
				question: 'How to find data for the municipality or region?',
				answer:
					'In the Area section, there is a choice of four types of map views (but also data aggregation): Serbia, regions, municipalities and points. If Serbia is selected for the area, only the national border will be shown on the map. If you have selected regions, the borders of the administrative districts will be displayed on the map. If you have selected municipalities, the borders of the municipalities will be shown on the map. Finally, if you have selected points on the map, the boundaries of the individual points (“pixels”) of the map will be displayed (picture below). If you click on the map where only the Serbian border is, the time series of the selected parameter will be displayed in the lower part of the portal, which is averaged over the entire territory (spatial mean value). If you have selected regions or municipalities, clicking on the appropriate region or municipality will give you a time series of size that is spatially averaged over the territory of the relevant administrative district or municipality. Finally, if you have selected a point, clicking on the individual points will give you a time series for that point you clicked on. You can see the exact list of regions (administrative districts) and municipalities and their abbreviations at the bottom of this page.',
				info: '{q16AnswerSlot2} {q16AnswerSlot3} {q16AnswerSlot4} {q16AnswerSlot5}',
				table: {
					title: '',
					info: '',

					headers: [
						'National border',
						'Region’s border',
						'Municipality’s border',
						'Points (”pixels”)',
					],

					rows: [
						[
							'q16-answer-img1.jpg',
							'q16-answer-img2.jpg',
							'q16-answer-img3.jpg',
							'q16-answer-img4.jpg',
						],
					],
				},
			},

			question17: {
				question: 'How to find time series for a whole country, region, municipality or a point?',
				answer:
					'Once you have selected the variable, e.g., the number of summer days index, in the Area section you can select municipalities if you are interested in one of the municipalities in Serbia. After this selection in the portal menu, the borders of individual municipalities will be displayed on the map. When you click on the municipality of interest in the lower part of the portal, the entire time series of the selected variable will be displayed, and on the map that municipality will become shaded (picture below). If you do not know the exact position of the municipality of interest there will be a box where you can type the name of the region or municipality.',
				images: ['q17-answer-img1.eng.png'],
			},

			question18: {
				// slots: netcdf
				question: 'What is {netcdf} format?',
				// slots: netcdf1, q18AnswerSlot2, here, netcdf2, ncdump
				answer:
					'{netcdf1} (Network Common Data Form) is a set of software libraries and self-describing, machine-independent data formats that support the creation, access, and sharing of array-oriented scientific data. More about format can be found {here}.',

				table: {
					title: '',
					info: '',

					// slots: netcdf
					headers: ['Meta data in one of {netcdf} files.'],
					rows: [
						[
							'dimensions:\n' +
								'    time = UNLIMITED ; // (25933 currently)\n' +
								'    longitude = 46 ;\n' +
								'    latitude = 48 ;\n' +
								'variables:\n' +
								'    double time(time) ;\n' +
								'   \t time:standard_name = "time" ;\n' +
								'   \t time:long_name = "Time in days" ;\n' +
								'   \t time:units = "days since 1950-01-01 00:00:00" ;\n' +
								'   \t time:calendar = "standard" ;\n' +
								'   \t time:axis = "T" ;\n' +
								'    double longitude(longitude) ;\n' +
								'   \t longitude:standard_name = "longitude" ;\n' +
								'   \t longitude:long_name = "Longitude values" ;\n' +
								'   \t longitude:units = "degrees_east" ;\n' +
								'   \t longitude:axis = "X" ;\n' +
								'    double latitude(latitude) ;\n' +
								'   \t latitude:standard_name = "latitude" ;\n' +
								'   \t latitude:long_name = "Latitude values" ;\n' +
								'   \t latitude:units = "degrees_north" ;\n' +
								'   \t latitude:axis = "Y" ;\n' +
								'    double tas(time, latitude, longitude) ;\n' +
								'   \t tas:standard_name = "air_temperature" ;\n' +
								'   \t tas:long_name = "mean temperature" ;\n' +
								'   \t tas:units = "Celsius" ;\n' +
								'   \t tas:_FillValue = -9999. ;\n' +
								'   \t tas:missing_value = -9999. ;\n' +
								'\n' +
								'// global attributes:\n' +
								'   \t :CDI = "Climate Data Interface version 1.9.9 (https://mpimet.mpg.de/cdi)" ;\n' +
								'   \t :Conventions = "CF-1.4" ;\n' +
								'      \t :pod = "obs" ;\n' +
								'   \t :var = "tas" ;\n' +
								'   \t :viz = "vre" ;\n' +
								'   \t :baz = "1950-2020" ;\n' +
								'   \t :ppr = "na" ;\n' +
								'   \t :sce = "na" ;\n' +
								'   \t :zag = "na" ;\n' +
								'   \t :sez = "d" ;\n' +
								'   \t :mod = "eobs" ;\n' +
								'   \t :NCO = "netCDF Operators version 4.8.1 (Homepage = http://nco.sf.net, Code = http://github.com/nco/nco)" ;',
						],
					],
				},
			},

			question19: {
				// slots: netcdf
				question: 'Which software can be used to open the {netcdf} files?',
				// slots: netcdf, here
				answer: 'The extended list can be found {here}.',
			},

			question20: {
				// slots: csv
				question: 'What is {csv} format?',
				answer:
					'A comma-separated values (CSV) file is a delimited text file that uses a comma to separate values.',
				table: {
					title: '',
					info: '',

					headers: ['Example of CSV file.'],
					rows: [
						[
							'srb: SRBIJA/SRBIJA\n' +
								'godina,mesec,dan,tas(C)\n' +
								'1950,01,01,-4.4053\n' +
								'1950,01,02,-4.9937\n' +
								'1950,01,03,-2.3867\n' +
								'1950,01,04,-1.1723\n' +
								'1950,01,05,-3.1368\n' +
								'1950,01,06,0.32721\n' +
								'1950,01,07,0.76474\n' +
								'1950,01,08,0.79946\n' +
								'1950,01,09,1.6535\n' +
								'1950,01,10,0.56131\n' +
								'1950,01,11,-4.5819\n' +
								'1950,01,12,-13.320\n' +
								'1950,01,13,-11.583\n' +
								'...\n',
						],
					],
				},
			},

			question21: {
				// slots: csv
				question: 'Which software can be used for the {csv} files?',
				// slots: spreadsheet, here
				answer: '',
				list: {
					title: '',
					info: '',
					items: [
						'Microsoft Excel',
						'Apple Numbers',
						'Google Sheets',
						'OpenOffice and LibreOffice',
						'Text Editors, and many others.',
					],
				},
			},

			question22: {
				question:
					'Is there are option to download data without plotting map or time series on portal?',
				// slots: rawFiles, netcdf, list
				answer: 'Yes, please use page {rawFiles}.',
				list: {
					title: '',
					info: '',
					items: [''],
				},
			},
			question23: {
				question: 'List of acronyms for essential climate variables and climate indices.',
				answer: '',
				table: {
					title: '',
					info: '',

					headers: ['Acronym', 'Long Name', 'Unit', 'Unit of\nchange'],
					rows: [], // variablesTable is used
				},
			},

			question24: {
				question: 'List of the ensemble mebers.',
				answer: '',
				table: {
					title: '',
					info: '',
					headers: ['Ensemble member acronym', 'Regional model', 'Global model'],
					rows: [
						['gcm-ICHEC-EC-EARTH-rcm-CLMcom-CCLM4-8-17', 'CLMcom-CCLM4-8-17', 'ICHEC-EC-EARTH'],
						['gcm-ICHEC-EC-EARTH-rcm-DMI-HIRHAM5', 'DMI-HIRHAM5', 'ICHEC-EC-EARTH'],
						['gcm-ICHEC-EC-EARTH-rcm-KNMI-RACMO22E', 'KNMI-RACMO22E', 'ICHEC-EC-EARTH'],
						['gcm-MOHC-HadGEM2-ES-rcm-CLMcom-CCLM4-8-17', 'CLMcom-CCLM4-8-17', 'MOHC-HadGEM2-ES'],
						['gcm-MOHC-HadGEM2-ES-rcm-KNMI-RACMO22E', 'KNMI-RACMO22E', 'MOHC-HadGEM2-ES'],
						['gcm-MPI-M-MPI-ESM-LR-rcm-CLMcom-CCLM4-8-17', 'CLMcom-CCLM4-8-17', 'MPI-M-MPI-ESM-LR'],
						[
							'gcm-MPI-M-MPI-ESM-LR-rcm-MPI-CSC-REMO20091',
							'MPI-CSC-REMO2009',
							'MPI-M-MPI-ESM-LR (r1i1p1)',
						],
						[
							'gcm-MPI-M-MPI-ESM-LR-rcm-MPI-CSC-REMO20092',
							'MPI-CSC-REMO2009',
							'MPI-M-MPI-ESM-LR (r2i1p1)',
						],
					],
				},
			},

			question25: {
				question: 'List of areas',
				answer: '',

				tableList: [
					{
						title: 'Serbia',
						info: '',

						headers: ['Abbreviation', 'Area'],
						rows: [['srb', 'SRBIJA']],
					},
					{
						title: 'Regions (Administrative districts)',
						info:
							'(*) Belgrade is not a region (administrative district), but it is included in this list.',

						headers: ['Abbreviation', 'Region', 'Abbreviation', 'Region'],
						rows: [
							['beo', 'BEOGRAD*', 'pir', 'PIROTSKI'],
							['boruo', 'BORSKI', 'pod', 'PODUNAVSKI'],
							['bra', 'BRANIČEVSKI', 'pom', 'POMORAVSKI'],
							['jab', 'JABLANIČKI', 'pri', 'PRIZRENSKI'],
							['jubac', 'JUŽNO BAČKI', 'rasi', 'RASINSKI'],
							['juban', 'JUŽNO BANATSKI', 'rask', 'RAŠKI'],
							['kol', 'KOLUBARSKI', 'sebac', 'SEVERNO BAČKI'],
							['kos', 'KOSOVSKI', 'seban', 'SEVERNO BANATSKI'],
							['kmit', 'KOSOVSKO-MITROVAČKI', 'srban', 'SREDNJO BANATSKI '],
							['kpom', 'KOSOVSKO-POMORAVSKI', 'sre', 'SREMSKI'],
							['mac', 'MAČVANSKI', 'sum', 'ŠUMADIJSKI'],
							['mor', 'MORAVIČKI', 'top', 'TOPLIČKI'],
							['nis', 'NIŠAVSKI', 'zaj', 'ZAJEČARSKI'],
							['pci', 'PČINJSKI', 'zabac', 'ZAPADNO BAČKI'],
							['pecuo', 'PČINJSKI', 'zla', 'ZLATIBORSKI'],
						],
					},
					{
						title: 'Municipalities',
						// slots: here
						info: 'You can download a list of Areas ~10x10km ("pixels") {here}.',

						headers: [
							'Abbreviation',
							'Municipalitie',
							'Abbreviation',
							'Municipalitie',
							'Abbreviation',
							'Municipalitie',
						],
						rows: townshipTableLat,
					},
				],
			},
		},

		climateIndexes: {
			title: 'Climate indices definitions',
			info: '',

			// slots:
			doiLink: '10.1175/2009JCLI2909.1',

			// var: Broj letnjih dana (su)
			index1: {
				definition: 'Annual count of days when daily maximum temperature is great then 25°C',
				usage: '',
			},

			// var: Broj tropskih dana (td)
			index2: {
				definition: 'Annual count of days when daily maximum temperature is great then 30°C.',
				usage: '',
			},

			// var: Broj dana sa temperaturom iznad 35 °C (txge35)
			index3: {
				definition:
					'Annual count of days when daily maximum temperature is great or equal then 35°C.',
				usage: '',
			},

			// var: Broj tropskih noći (tr)
			index4: {
				definition: 'Annual count of days when daily minimum temperature great then 20 °C.',
				usage: '',
			},

			// var: Broj mraznih dana (fd)
			index5: {
				definition: 'Annual count of days when daily minimum temperature is below 0°C.',
				usage: '',
			},

			// var: Broj ledenih dana (id)
			index6: {
				definition: 'Annual count of days when daily maximum temperature is below 0°C.',
				usage: '',
			},

			// var: Dužina vegetacionog perioda (5°C) (gsl5)
			index7: {
				definition:
					'Annual count between the first span of at least 6 days with daily mean temperature is above 5 °C and the first span after July 1st of 6 days with daily mean temperature below 5 °C.',
				usage: '',
			},

			// var: Dužina vegetacionog perioda (10°C) (gsl10)
			index8: {
				definition:
					'Annual count between the first span of at least 6 days with daily mean temperature is above 10 °C and the first span after July 1st of 6 days with daily mean temperature below 10 °C.',
				usage: '',
			},

			// var: Broj toplotnih talasa (hwfi)
			index9: {
				definition:
					'Number of heat waves during the year. Heat wave is a period of at least 6 consecutive days when daily maximum temperature is above 90th percentile.',
				usage: '',
			},

			// var: Dužina toplotnih talasa (hwfid)
			index10: {
				definition:
					'Annual count of days with at least 6 consecutive days when daily maximum temperature is above 90th percentile.',
				usage: '',
			},

			// var: Broj toplih perioda (hwdi)
			index11: {
				definition:
					'Number of heat waves during the year. Warm period is a period of at least 6 consecutive days when daily maximum temperature is above 50th percentile.',
				usage: '',
			},

			// var: Dužina toplih perioda (hwdid)
			index12: {
				definition:
					'Annual count of days with at least 6 consecutive days when daily maximum temperature is above 50th percentile.',
				usage: '',
			},

			// var: Broj dana sa padavinama većim od 20 mm (rr20)
			index13: {
				definition: 'Annual count of days when daily precipitation is above or equal to 20 mm.',
				usage: '',
			},

			// var: Broj dana sa padavinama većim od 30mm (rr30)
			index14: {
				definition: 'Annual count of days when daily precipitation is above or equal to 30 mm.',
				usage: '',
			},

			// var: Maksimalne jednodnevne padavine (rx1d)
			index15: {
				definition: 'Annual maximum 1-day precipitation.',
				usage: '',
			},

			// var: Maksimalne petodnevne padavine (rx5d)
			index16: {
				definition: 'Annual maximum 5-day precipitation. ',
				usage: '',
			},

			// var: Uzastopni suvi dani (cdd)
			index17: {
				definition:
					'Maximum length of dry spell: maximum number of consecutive days with precipitation below 1mm.',
				usage: '',
			},

			// var: Uzastopni dani sa padavinama (cwd)
			index18: {
				definition:
					'Maximum length of wet spell: maximum number of consecutive days with dialy precipitation is above or equal to 1mm.',
				usage: '',
			},

			// var: SPEI indeks suše (spei6a)
			index19: {
				definition:
					'Measure of "drought" using the Standardised Precipitation Evapotranspiration Index on time scales of 3, 6 and 12 months. A drought measure specified using precipitation and evaporation (Vicente-Serrano et al. (2010) for details). SEPI6A is a SPEI calculated for 6 months period for August. In Serbia SEPI6A have high correlation with maize production (figure below). Calibration period for SEPI available on platform is 1961-1990.\n\n' +
					'Vicente-Serrano et al., 2010, A Multiscalar Drought Index Sensitive to Global Warming: The Standardized Precipitation Evapotranspiration Index, J. Climate, 23, 1696–718.',
				usage: '',
				images: ['index19.img1.jpg'],
				infoList: [
					'Figure: Annual maize production and spei6a index. (Maize data: Statistical Office of the Republic of Serbia).',
				],
			},

			// var: Elenbergov indeks (eq)
			index20: {
				definition: 'EQ=1000*Tjul/Pann\n\nTjul July mean temperature, a Pann annual precipitation.',
				usage: '',
				infoList: [
					'More details',
					'Stojanović D. et al., 2013, Prediction of the European beech (Fagus sylvatica L.) xeric limit using a regional climate model: An example from southeast Europe, Agricultural and Forest Meteorology, 176, 94-103.',
				],
			},

			// var: Indeks aridnosti (fai)
			index21: {
				definition:
					'FAI=100*(Tjul_aug)/(Pmay_jul + Pjul_aug)\n\n' +
					'Tjul_aug mean temperature for period July to August, Pmay_jul accumulated precipitation for period May to July, and Pjul_avgust accumulated precipitation for period July to August.',
				usage: '',

				infoList: [
					'More details',
					'Führeri et al., 2011, Application of a new aridity index in Hungarian forestry practice, Idöjárás, 115, 205–216.',
				],
			},

			// var: Hidro-termalni koeficijent (htc)
			index22: {
				definition:
					'HTC=10*Psum/Tsum\n\n' +
					'Psum sum of daily precipitation over period when monthly mean temperature is above 10 °C and Tsum is temperature sum over same period.',
				usage: '',
				infoList: [
					'More details',
					'Vukovic, A., Vujadinovic, M., 2018, Study on Climate Change in the Western Balkans Region, Regional Cooperation Council, link(https://www.rcc.int/pubs/62/study-on-climate-change-in-the-western-balkans-region). ',
				],
			},

			// var:
			index23: {
				definition: '',
				usage: '',
			},
		},

		fileNames: {
			title: 'Portal file name convention',
			info: '',

			info1: {
				title: 'Convention for netcdf file names - section Observations (gridded climatology).',
				info: '',

				infoList: ['Attribute value'],

				definitionList: [
					{
						prefix: 'Generic name:',
						definition: 'pod_var_viz_bazp_prop_sce_zag_sez_model.nc',
					},
					{
						prefix: 'index: ',
						definition:
							'su, td, txge35, tr, fd, id, gsl5, gsl10, hwfi, hwfid, hwdi, hwdid, rr20, rr30, rx1d, rx5d, cdd, cwd, spei6a, eq, fai, htc',
					},
					{
						prefix: 'pod',
						definition: ' – data source, obs indicate that file contains gridded climatology.',
					},
					{
						prefix: 'var',
						definition: ' – variable short name.',
					},
					{
						prefix: 'viz',
						definition:
							' – viz=vre for mean values over reference period, viz=pro is for change with respect to reference period.',
					},
					{
						prefix: 'bazp',
						definition: ' – reference period.',
					},
					{
						prefix: 'prop',
						definition: ' – change period.',
					},
					{
						prefix: 'sce',
						definition: ' – climate scenario, in case of grided climatology, the value is na.',
					},
					{
						prefix: 'zag',
						definition: ' – currently not used and it is always na.',
					},
					{
						prefix: 'sez',
						definition:
							' – seasons short name, annual (god), winter (djf), spring (mam), summer (jja) and fall (son).',
					},
					{
						prefix: 'model',
						definition: ' – in case of grided climatology it is always eobs.',
					},
				],
				tableList: [
					{
						title: '',
						info: '',
						headers: ['Attribute', '1st dep. level', '2nd dep. level', 'Attribute value'],
						rows: [
							['pod', '', '', 'obs'],
							['var', '', '', 'tas; tasmax; tasmin; pr; index'],
							['viz', '', '', 'vre; pro'],
							['bazp', '', '', '1961-1990; 1971-2000; 1981-2010'],
							['prop [viz, bazp]', 'viz = vre', '', 'na'],
							[
								'prop [viz, bazp]',
								'viz = pro',
								'bazp = 1961-1990; 1971-2000; 1981-2010',
								'2001-2020; 2011-2020',
							],
							['sce', '', '', 'na'],
							['zag', '', '', 'na'],
							['sez [var]', 'var = tas; tasmax; tasmin; pr', '', 'god; djf; mam; jja; son'],
							['sez [var]', 'var = index', '', 'god'],
							['model', 'viz = vre', '', 'eobs'],
						],
					},
				],
			},

			info2: {
				title: 'Convention for csv file names - section Observations (gridded climatology). ',
				info: '',

				infoList: ['Attribute value'],

				definitionList: [
					{
						prefix: 'Generic name: ',
						definition: 'pod_var_viz_bazp_prop_sce_zag_sez_model_reg.csv',
					},
					{
						prefix: 'index:',
						definition:
							'su, td, txge35, tr, fd, id, gsl5, gsl10, hwfi, hwfid, hwdi, hwdid, rr20, rr30, rx1d, rx5d, cdd, cwd, spei6a, eq, fai, htc',
					},
					{
						prefix: 'pod',
						definition: ' – data source, obs indicate that file contains gridded climatology.',
					},
					{
						prefix: 'var',
						definition: ' – variable short name.',
					},
					{
						prefix: 'viz',
						definition:
							' – viz=vre is for absolute values, viz=pro is for anomalies with respect to the reference period.',
					},
					{
						prefix: 'bazp',
						definition:
							' – if viz=vre then bazp=1950-2020, if viz=pro then bazp has values of the reference period.',
					},
					{
						prefix: 'prop',
						definition: ' – if viz=vre then prop=na, if viz=pro then prop=1951-2020.',
					},
					{
						prefix: 'sce',
						definition: ' – climate scenario, in case of grided climatology, the value is na.',
					},
					{
						prefix: 'zag',
						definition: ' – currently not used and it is always na.',
					},
					{
						prefix: 'sez',
						definition:
							' – time frequency of time series, sez=g annual, sez=m monthly, sez=d daily.',
					},
					{
						prefix: 'model',
						definition: ' – in case of grided climatology it is always eobs.',
					},
					{
						prefix: 'reg',
						// slots: here
						definition: ' – area short name. You can find list of short names & areas {here}.',
					},
				],

				tableList: [
					{
						title: '',
						info: '',
						headers: ['Attribute', '1st dep. level', '2nd dep. level', 'Attribute value'],
						rows: [
							['pod', '', '', 'obs'],
							['var', '', '', 'tas; tasmax; tasmin; pr; index'],
							['viz', '', '', 'vre; pro'],
							['bazp [viz]', 'viz = vre', '', '1950-2020'],
							['bazp [viz]', 'viz = pro', '', '1961-1990; 1971-2000; 1981-2010'],
							['prop [viz]', 'viz = vre', '', 'na'],
							['prop [viz]', 'viz = pro', 'bazp = 1961-1990; 1971-2000; 1981-2010', '1950-2020'],
							['sce', '', '', 'na'],
							['zag', '', '', 'na'],
							['sez [viz, var]', 'viz = vre', 'var = tas; tasmax; tasmin; pr', 'g; m; d'],
							['sez [viz, var]', 'viz = vre', 'var = index', 'g'],
							['sez [viz, var]', 'viz = pro', 'var = tas; tasmax; tasmin; pr', 'g'],
							['sez [viz, var]', 'viz = pro', 'var = index', 'g'],
							['model', '', '', 'eobs'],
							['reg', '', '', 'abbreviation of the area'],
						],
					},
				],
			},

			info3: {
				title:
					'Convention for netcdf file names - section Future climate projections (model results).',
				info: '',

				infoList: ['Attribute value'],

				definitionList: [
					{
						prefix: 'Generic name:',
						definition: 'pod_var_viz_bazp_prop_sce_zag_sez_model.nc',
					},
					{
						prefix: 'index:',
						definition:
							'su, td, txge35, tr, fd, id, gsl5, gsl10, hwfi, hwfid, hwdi, hwdid, rr20, rr30, rx1d, rx5d, cdd, cwd, spei6a, eq, fai, htc',
					},
					{
						prefix: 'model acronym:',
						definition:
							'gcm-ICHEC-EC-EARTH-rcm-CLMcom-CCLM4-8-17; gcm-ICHEC-EC-EARTH-rcm-DMI-HIRHAM5; gcm-ICHEC-EC-EARTH-rcm-KNMI-RACMO22E; gcm-MOHC-HadGEM2-ES-rcm-CLMcom-CCLM4-8-17; gcm-MOHC-HadGEM2-ES-rcm-KNMI-RACMO22E; gcm-MPI-M-MPI-ESM-LR-rcm-CLMcom-CCLM4-8-17; gcm-MPI-M-MPI-ESM-LR-rcm-MPI-CSC-REMO20091; gcm-MPI-M-MPI-ESM-LR-rcm-MPI-CSC-REMO20092',
					},
					{
						prefix: 'pod',
						definition: ' – data source, mod indicate that file contains model results.',
					},
					{
						prefix: 'var',
						definition: ' – variable short name.',
					},
					{
						prefix: 'viz',
						definition:
							' – viz=vre for mean values over reference period, viz=pro is for change with respect to reference period.',
					},
					{
						prefix: 'bazp',
						definition: ' – reference period.',
					},
					{
						prefix: 'prop',
						definition: ' – change period.',
					},
					{
						prefix: 'sce',
						definition: ' – climate scenario.',
					},
					{
						prefix: 'zag',
						definition: ' – currently not used and it is always na.',
					},
					{
						prefix: 'sez',
						definition:
							' – seasons short name, annual (god) winter (djf), spring (mam), summer (jja) and fall (son)...',
					},
					{
						prefix: 'model',
						definition:
							' – model acronym or ens is for ensemble median, ens25 is for 25th percentile of ensemble, and ens75 is for 75th percentile of ensemble',
					},
				],

				tableList: [
					{
						title: '',
						info: '',
						headers: ['Attribute', '1st dep. level', '2nd dep. level', 'Attribute value'],
						rows: [
							['pod', '', '', 'mod'],
							['var', '', '', 'tas; tasmax; tasmin; pr; index'],
							['viz', '', '', 'vre; pro'],
							['bazp', '', '', '1971-2000; 1986-2005'],
							['prop [viz, bazp]', 'viz = vre', '', 'na'],
							[
								'prop [viz, bazp]',
								'viz = pro',
								'bazp = 1971-2000',
								'2011-2040; 2041-2070; 2071-2100',
							],
							[
								'prop [viz, bazp]',
								'viz = pro',
								'bazp = 1986-2005',
								'2021-2040; 2041-2060; 2081-2100',
							],
							['sce', '', '', 'rcp45; rcp85'],
							['zag', '', '', 'na'],
							['sez [var]', 'var = tas; tasmax; tasmin; pr', '', 'god; djf; mam; jja; son'],
							['sez [var]', 'var = index', '', 'god'],
							['model [viz]', 'viz = vre', '', 'ens; model abbreviations'],
							['model [viz]', 'viz = pro', '', 'ens; ens25; ens75; model abbreviations'],
						],
					},
				],
			},

			info4: {
				title:
					'Convention for csv file names - section Future climate projections (model results).',
				info: '',

				infoList: ['Attribute value'],

				definitionList: [
					{
						prefix: 'Generic name:',
						definition: 'pod_var_viz_bazp_prop_sce_zag_sez_model_reg.csv',
					},
					{
						prefix: 'index:',
						definition:
							' su, td, txge35, tr, fd, id, gsl5, gsl10, hwfi, hwfid, hwdi, hwdid, rr20, rr30, rx1d, rx5d, cdd, cwd, spei6a, eq, fai, htc',
					},
					// 10
					{
						prefix: 'pod',
						definition: ' – data source, mod indicate that file contains model results.',
					},
					{
						prefix: 'var',
						definition: ' – variable short name.',
					},
					{
						prefix: 'viz',
						definition:
							' – viz=vre is for absolute values, viz=pro is for anomalies with respect to the reference period.',
					},
					{
						prefix: 'bazp',
						definition:
							' – if viz=vre then bazp=1950-2100, if viz=pro then bazp has values of the reference period.',
					},
					{
						prefix: 'prop',
						definition: ' – if viz=vre then prop=na, if viz=pro then prop=1950-2100.',
					},
					{
						prefix: 'sce',
						definition: ' – climate scenario.',
					},
					{
						prefix: 'zag',
						definition: ' – currently not used and it is always na.',
					},
					{
						prefix: 'sez',
						definition:
							' – time frequency of time series, sez=g annual, sez=m monthly, sez=d daily.',
					},
					{
						prefix: 'model',
						definition: ' – in case of model results it is always ens.',
					},
					{
						prefix: 'reg',
						// slots: here
						definition: ' – area short name. You can find list of short names & areas {here}.',
					},
				],

				tableList: [
					{
						title: '',
						info: '',
						headers: ['Attribute', '1st dep. level', '2nd dep. level', 'Attribute value'],
						// 15
						rows: [
							['pod', '', '', 'mod'],
							['var', '', '', 'tas; tasmax; tasmin; pr; index'],
							['viz', '', '', 'vre; pro'],
							['bazp [viz]', 'viz = vre', '', '1951-2100'],
							['bazp [viz]', 'viz = pro', '', '1971-2000; 1986-2005'],
							['prop [viz]', 'viz = vre', '', 'na'],
							['prop [viz]', 'viz = pro', '', '1951-2100'],
							['sce', '', '', 'rcp45; rcp85'],
							['zag', '', '', 'na'],
							['sez [viz, var]', 'viz = vre', 'var = tas; tasmax; tasmin; pr', 'g; m; d'],
							['sez [viz, var]', 'viz = vre', 'var = index', 'g'],
							['sez [viz, var]', 'viz = pro', 'var = tas; tasmax; tasmin; pr', 'g'],
							['sez [viz, var]', 'viz = pro', 'var = index', 'g'],
							['model', '', '', 'ens'],
							['reg', '', '', 'abbreviation of the area'],
						],
					},
				],
			},

			info5: {
				title: 'Convention for netcdf file names – Raw files page.',
				info: '',

				infoList: ['Attribute value'],

				definitionList: [
					{
						prefix: 'Generic name:',
						definition: 'pod_var_viz_bazp_prop_sce_zag_sez_model.extension',
					},
					{
						prefix: 'index:',
						definition:
							' su, td, txge35, tr, fd, id, gsl5, gsl10, hwfi, hwfid, hwdi, hwdid, rr20, rr30, rx1d, rx5d, cdd, cwd, spei6a, eq, fai, htc',
					},
					{
						prefix: 'model acronym:',
						definition:
							'gcm-ICHEC-EC-EARTH-rcm-CLMcom-CCLM4-8-17; gcm-ICHEC-EC-EARTH-rcm-DMI-HIRHAM5; gcm-ICHEC-EC-EARTH-rcm-KNMI-RACMO22E; gcm-MOHC-HadGEM2-ES-rcm-CLMcom-CCLM4-8-17; gcm-MOHC-HadGEM2-ES-rcm-KNMI-RACMO22E; gcm-MPI-M-MPI-ESM-LR-rcm-CLMcom-CCLM4-8-17; gcm-MPI-M-MPI-ESM-LR-rcm-MPI-CSC-REMO20091; gcm-MPI-M-MPI-ESM-LR-rcm-MPI-CSC-REMO20092',
					},
					// 9
					{
						prefix: 'pod',
						definition: ' – data source.',
					},
					{
						prefix: 'var',
						definition: ' – variable short name.',
					},
					{
						prefix: 'viz',
						definition: '– it is always vre.',
					},
					{
						prefix: 'bazp',
						definition: ' – data frame of the data.',
					},
					{
						prefix: 'pro',
						definition: ' – it is always na.',
					},
					{
						prefix: 'sce',
						definition: ' – climate scenario.',
					},
					{
						prefix: 'zag',
						definition: ' – it is always na.',
					},
					{
						prefix: 'sez',
						definition:
							' – time frequency of time series, sez=g annual, sez=m monthly, sez=d daily.',
					},
					{
						prefix: 'model',
						definition: ' – linked to the data source.',
					},
					{
						prefix: 'reg',
						definition: ' – file extension.',
					},
				],

				tableList: [
					{
						title: '',
						info: '',
						headers: [
							['', '', 'Dataset', ''],
							[
								'',
								'Observations',
								'Future climate projections',
								'Second national communications data',
							],
							['Attribute', 'Value', 'Value', 'Value'],
						],
						rows: [
							['pod', 'obs', 'mod', 'mod'],
							[
								'var',
								'tas/tasmax/tasmin/pr/indeks',
								'tas/tasmax/tasmin/pr/indeks',
								'tas/tasmax/tasmin/pr',
							],
							['viz', 'vre', 'vre', 'vre'],
							['bazp', '1950-2020', '1951-2100', '1951-2100'],
							['prop', 'na', 'na', 'na'],
							['sce', 'na', 'rcp45/rcp85', 'a1b'],
							['zag', 'na', 'na', 'na'],
							['sez', 'g/m/d', 'g/m/d', 'g/m/d'],
							['model', 'eobs', 'modelens', 'ebupom'],
							[
								'extension',
								'nc',
								'zip (with 8 netcdf files, each file will have model acronym)',
								'nc',
							],
						],
					},
				],
			},
		},
		citation: {
			title: 'Acknowledgement',
			info: '',

			// slots
			platformUrl: window.location.origin,
			uerra: 'UERRA',
			ecad: 'ECA&D',
			colorBrewer: 'ColorBrewer',

			infoList: [
				// 0
				'Citation and acknowledgement of this platform',
				// 1
				'Whenever you publish research or applications based on data from this platform, please include the following platform acknowledgement and citations.',
				// 2
				'Citation of the platform',
				// 3
				// slots: platformUrl
				'Ministry for environmental protection, 2022, Digital climate and climate change atlas of the republic of Serbia. Project „Advancing medium and long-term adaptation planning in the republic of Serbia “, {platformUrl}',
				// 4
				'Acknowledgement of the platform',
				// 5
				'We acknowledge the data from the digital climate platform developed by project Advancing medium and long-term adaptation planning in the republic of Serbia, funded by Green Climate Fund, Ministry of Agriculture, Forestry and Water Management and Ministry of Environmental Protection of the republic of Serbia.',
				// 6
				'Acknowledgement for the data used for platform development',
				// 7
				'EOBS data',
				// 8
				// slots: uerra, ecad
				'We acknowledge the E-OBS dataset from the EU-FP6 project {uerra} and the Copernicus Climate Change Service, and the data providers in the {ecad} project. Cornes, R., G. van der Schrier, E.J.M. van den Besselaar, and P.D. Jones. 2018: An Ensemble Version of the E-OBS Temperature and Precipitation Datasets, J. Geophys. Res. Atmos., 123. doi:10.1029/2017JD028200',
				// 9
				'CORDEX data',
				// 10
				"We acknowledge the World Climate Research Programme's Working Group on  Regional Climate, and the Working Group on Coupled Modelling, former coordinating body of CORDEX and responsible panel for CMIP5. We also thank the climate modelling groups (MPI-CSC, KNMI, DMI and CLMcom) for producing and making available their model output. We also acknowledge the Earth System Grid Federation infrastructure an international effort led by the U.S. Department of Energy's Program for Climate Model Diagnosis and Intercomparison, the European Network for Earth System Modelling and other partners in the Global Organisation for Earth System Science Portals (GO-ESSP).",
				// 11
				'Map colors',
				// 12
				// slots: colorBrewer: (www.ColorBrewer.org)
				'Map colors based on {colorBrewer}, by Cynthia A. Brewer, Penn State.',
			],
		},
	},
	contact: {
		title: 'Contact',
		info: 'Please fill out this form and we will get back to you as soon as possible.',
	},
	about: {
		title: '',
		info: '',

		adaptationPlanning: {
			title: 'Advancing medium and long-term adaptation planning in the Republic of Serbia (NAP)',
			info: '',

			// List of paragraphs
			infoList: {
				title: '',
				info: '',
				items: [
					'The United Nations Development Programme (UNDP), acting as an implementing agency of the Green Climate Fund (GCF), is providing assistance to the Serbian Government in the implementation of the GCF funded project “Advancing medium and long-term adaptation planning in the Republic of Serbia (NAP project). Project duration is from 2019-2022 and the main objective of the project is to improve Serbia’s legal framework for addressing climate change vulnerabilities and strengthen institutional capacities for integrating climate change adaptation (CCA) measures into decision making and investment planning. Project activities will address the main barriers to the integration of CCA into national, sectorial and local government planning and budgeting and support the formulation of financing strategies and mechanisms for the scaling up of medium- and long-term adaptation.',
					// list
					'The project has developed multi-hazard comprehensive risk and vulnerability assessments of climate change impact, followed by sectorial adaptation measures, for the key sectors (Agriculture and Water Management, Infrastructure, Energy, Forestry, Health, Biodiversity) as basis for development of National Adaptation Planning document (NAP).',
					'The project is implemented in cooperation with The Ministry of Agriculture, Forestry and Water Management and the Ministry of Environmental Protection.',
				],
			},
			// List in on of upper paragraphs
			list: {
				title: 'The project focus is on:',
				info: '',
				items: [
					'Strengthening legal and institutional framework for climate change adaptation (CCA);',
					'Assessing and addressing immediate national and subnational CCA policy and institutional capacity gaps;',
					'Developing of the National Climate Change Adaptation Plan (NAP) and integration of the CCA into national and subnational planning processes;',
					'Improving the Nationally Determined Contributions (NDC);',
					'Developing the web-based application and platform for Climate Change Vulnerability Assessments and Adaptation (CCA);',
					'Conducting trainings, workshops and consultative meetings on CCA with representatives from relevant institutions.',
				],
			},
		},

		// List of paragraphs
		climateAtlas: {
			title: 'Digital Climate Atlas of Serbia',
			info: '',

			// slots
			// link: https://cordex.org/
			cordex: 'CORDEX',
			// link: https://climate.copernicus.eu/,
			climateCopernicus: 'Copernicus Climate Change Service',
			// link: 'https://geosrbija.rs/'
			geoSerbia: 'geospatial information system of the Republic of Serbia',
			// link: https://weareneopix.com/
			neopix: 'Neopix',

			infoList: {
				title: '',
				info: '',

				items: [
					'Integration of adaptation to climate change into medium- and long-term planning requires strong evidence based decision-making using the best available climate and socio-economic information. For this purpose, a Digital Climate Atlas of Serbia (web platform) has been established under the NAP project. Beside climate data used for specific risk & vulnerability assessments, it includes:',
					// list
					'All climate data presented in the Digital Climate Atlas of Serbia is publicly available and can be used by different stakeholders for vulnerability and risk assessments, as well as for identification of adaptation options at sectoral, subnational and national levels, including for the priority areas/sectors (Agriculture & Water Management nexus, Energy, Transport and Construction infrastructure), as identified in the First (2010) and Second (2017) National Communications to the UNFCCC, and compliant with the national documents. Digital Climate Atlas will serve as the basis for all climate-related initiatives, to improve understanding of the baseline situation, improve vulnerability assessments, strengthen justification for CCA interventions and improve decision-making capacity. Vulnerability assessments developed with support of Digital Climate Atlas, will contribute to: disaster risk reduction (DRR) programming, reduction of losses and damages at the local and the national level.',
					// slots: cordex, climateCopernicus, geoSerbia
					'Digital Climate Atlas of Serbia is based on the data provided by {cordex} the World Climate Research Programme initiative, {climateCopernicus} that provides climate monitoring products for Europe based on surface in-situ observations by National Meteorological Services (in case of Serbia: Republican Hydrometeorological Service), as well as {geoSerbia}. This makes data from the platform compatible, comparable and easy to integrate with many other online available data sets and data services including data on different platforms related to climate services.',
					// slots: neopix
					'Digital Climate Atlas of Serbia has been developed in cooperation with the Faculty of Physics, Belgrade University and {neopix}. ',

					'The Digital Climate Atlas of Serbia has been developed within the NAP Project, with UNDP support. The contents and designations in this web portal are the sole responsibility of the Ministry of Environmental protection, and do not necessarily reflect the views of UNDP.',
				],
			},
			list: {
				title: '',
				info: '',
				items: [
					'climate datasets, including observation (or data derived from observations, such as gridded climatology, or reanalysis) of the past, and regional climate model projections for the whole country, as well as at the sub-national and city/local level – the platform; and',
					'on-line visuals - maps and charts for different seasonal and annual analysis and data.',
				],
			},
		},
	},
	unit: {
		Celsius: '°C',
		'%': '%',
		mm: 'mm',
		spei: 'spei',
		fai: {
			index: 'fai',
		},
		eq: {
			index: 'eq',
		},
		htc: {
			index: 'htc',
		},
		No: {
			HW: 'number of events',
			WP: 'number of events',
			days: 'days',
		},
		Dur: {
			HW: 'days',
			WP: 'days',
		},
	},
});
