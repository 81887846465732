// eslint-disable-next-line import/no-extraneous-dependencies
import { Server } from 'miragejs';
import { BASE_URL, DEVELOPMENT } from '@/utils/constants.utils';

export default function ({ environment = DEVELOPMENT } = {}) {
	return new Server({
		environment,
		routes() {
			this.urlPrefix = process.env.VUE_APP_BASE_URL;

			this.passthrough();
			this.passthrough(`${BASE_URL}/**`);
		},
	});
}
