
import { defineComponent, ref } from 'vue';
import { CNavbarLink } from '@/models/CNavbarLink.model';
import { RouteName } from '@/enums/RouteName.enum';
import CLink from '@/components/shared/CLink.vue';
import CDropdown from '@/components/shared/CDropdown.vue';
import CButton from '@/components/shared/CButton.vue';
import CIconUpDown from '@/components/shared/CIconUpDown.vue';
import { ButtonType } from '@/enums/ButtonType.enum';
import { LOCALE_OPTIONS } from '@/utils/constants.utils';
import { LocaleEnum } from '@/enums/Locale.enum';
import { LocaleOption } from '@/models/LocaleOption.model';
import { LinkType } from '@/enums/LinkType.enum';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CNavbar',
	components: { CIconUpDown, CButton, CDropdown, CLink },
	setup() {
		const isDropdownOpen = ref<boolean>(false);

		return {
			ButtonType,
			LOCALE_OPTIONS,
			isDropdownOpen,
		};
	},
	data() {
		return {
			selectedLocal: '',
		};
	},
	computed: {
		lang(): string {
			return this.$route.params.lang as string;
		},
		navbarList(): CNavbarLink[] {
			return [
				new CNavbarLink({
					to: {
						name: RouteName.MAP,
						params: {
							lang: this.lang,
						},
					},
					name: RouteName.MAP,
					type: LinkType.NAV,
					label: this.$t('nav.map'),
				}),
				new CNavbarLink({
					to: {
						name: RouteName.ABOUT,
						params: {
							lang: this.lang,
						},
					},
					name: RouteName.ABOUT,
					type: LinkType.NAV,
					label: this.$t('nav.about'),
				}),
				new CNavbarLink({
					to: {
						name: RouteName.RAW_FILES,
						params: {
							lang: this.lang,
						},
					},
					name: RouteName.RAW_FILES,
					type: LinkType.NAV,
					label: this.$t('nav.rawFiles'),
				}),
				new CNavbarLink({
					to: {
						name: RouteName.CONTACT,
						params: {
							lang: this.lang,
						},
					},
					name: RouteName.CONTACT,
					type: LinkType.NAV,
					label: this.$t('nav.contact'),
				}),
				new CNavbarLink({
					to: {
						name: RouteName.INFO,
						params: {
							lang: this.lang,
						},
					},
					name: RouteName.INFO,
					type: LinkType.NAV,
					label: this.$t('nav.info'),
				}),
			];
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				const { pathname } = window.location;
				// [emptyStr, locale, routeName] = pathname.split('/')
				const [, locale] = pathname.split('/');
				this.selectedLocal = locale;
			},
		},
	},
	methods: {
		setLocale(locale: LocaleEnum): void {
			if (locale === this.selectedLocal) {
				return;
			}

			// remove `/:lang` from path
			const path = this.$route.path.split('/').slice(2).join('/');
			const { search } = window.location;
			const href = `/${locale}/${path}${search}`;

			window.location.replace(href);
		},
		getShort(locale: LocaleEnum, localeOptions: LocaleOption[]): string {
			return localeOptions.find((opt) => opt.locale === locale)?.shortName;
		},
	},
});
