/**
 *  Maps names form Filters.model.ts
 */
export enum FilterName {
  DATA= 'dataType',
  VISUALIZATION = 'visualization',
  VARIABLE_UUID = 'variableUuid',
  BASE_PERIOD_UUID = 'basePeriodUuid',
  CHANGE_PERIOD_UUID = 'changePeriodUuid',
  SCENARIO_UUID = 'scenarioUuid',
  HEATING = 'heating',
  SEASON = 'season',
  AREA = 'area'
}
