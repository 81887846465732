import { createRouter, createWebHistory } from 'vue-router';
import { LocaleEnum } from '@/enums/Locale.enum';
import { DEFAULT_LOCAL, i18n } from '@/localization/localization';
import { RouteName } from '@/enums/RouteName.enum';
import { routes } from './app-routes';

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const langParam = to.params.lang as LocaleEnum;
	const isParamGood = Object.values(LocaleEnum).includes(langParam);
	const language = (isParamGood ? langParam : '') || DEFAULT_LOCAL;
	i18n.global.locale = language;

	if (!isParamGood) {
		await router.push({
			name: RouteName.MAP,
			params: {
				lang: language,
			},
		});
	}
	next();
});

export default router;
