import { t } from '@/utils/utils';

// ['Acronym', 'Long Name', 'Unit', 'Unit of change'],
export const variablesTable = (): Array<Array<string>> => [
	[t('filters.variable.tas'), t('filters.variable.tasTooltip'), '°C', '°C'],
	[t('filters.variable.tasmax'), t('filters.variable.tasmaxTooltip'), '°C', '°C'],
	[t('filters.variable.tasmin'), t('filters.variable.tasminTooltip'), '°C', '°C'],
	[t('filters.variable.pr'), t('filters.variable.prTooltip'), 'mm', '%'],
	[t('filters.variable.su'), t('filters.variable.suTooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.td'), t('filters.variable.tdTooltip'), t('word.days'), t('word.days')],
	[
		t('filters.variable.txge35'),
		t('filters.variable.txge35Tooltip'),
		t('word.days'),
		t('word.days'),
	],
	[t('filters.variable.tr'), t('filters.variable.trTooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.fd'), t('filters.variable.fdTooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.id'), t('filters.variable.idTooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.gsl10'), t('filters.variable.gsl10Tooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.gsl5'), t('filters.variable.gsl5Tooltip'), t('word.days'), t('word.days')],
	[
		t('filters.variable.hwfi'),
		t('filters.variable.hwfiTooltip'),
		t('message.numberOfEvents'),
		t('message.numberOfEvents'),
	],
	[t('filters.variable.hwfid'), t('filters.variable.hwfidTooltip'), t('word.days'), t('word.days')],
	[
		t('filters.variable.hwdi'),
		t('filters.variable.hwdiTooltip'),
		t('message.numberOfEvents'),
		t('message.numberOfEvents'),
	],
	[t('filters.variable.hwdid'), t('filters.variable.hwdidTooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.rr20'), t('filters.variable.rr20Tooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.rr30'), t('filters.variable.rr30Tooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.rx1d'), t('filters.variable.rx1dTooltip'), 'mm', '%'],
	[t('filters.variable.rx5d'), t('filters.variable.rx5dTooltip'), 'mm', '%'],
	[t('filters.variable.cdd'), t('filters.variable.cddTooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.cwd'), t('filters.variable.cwdTooltip'), t('word.days'), t('word.days')],
	[t('filters.variable.spei6a'), t('filters.variable.spei6aTooltip'), 'spei', 'spei'],
	[t('filters.variable.fai'), t('filters.variable.eqTooltip'), 'fai', 'fai'],
	[t('filters.variable.htc'), t('filters.variable.tasTooltip'), 'htc', 'htc'],
];
