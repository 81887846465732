import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_navbar = _resolveComponent("c-navbar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_metainfo = _resolveComponent("metainfo")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_c_navbar),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_metainfo, null, {
      title: _withCtx((content) => [
        _createTextVNode(_toDisplayString(content?.metainfo?.title) + " - " + _toDisplayString(_ctx.$t('title')), 1)
      ]),
      _: 1
    })
  ], 64))
}