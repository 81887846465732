import { MapParams } from '@/api/endpoints/map/map.types';
import { FilterDataType } from '@/enums/FilterDataType.enum';
import { FilterVisualization } from '@/enums/FilterVisualization.enum';
import { Fill, Stroke } from 'ol/style';
import { Area } from '@/enums/Area.enum';
import { LocaleOption } from '@/models/LocaleOption.model';
import { LocaleEnum } from '@/enums/Locale.enum';
import { MapFiltersState } from '@/models/MapFiltersState.model';
import { CButtonRadioModel } from '@/models/CButtonRadio.model';
import { FilterName } from '@/enums/FilterName.enum';
import { i18n } from '@/localization/localization';
import { ChartDatasetConfig } from '@/models/ChartDatasetConfig';

const { t } = i18n.global;

export const DEVELOPMENT = 'development';
export const IS_ENV_DEVELOPMENT = process.env.NODE_ENV === DEVELOPMENT;
export const IS_MOCKSERVER_ENABLED = !!Number(process.env.VUE_APP_MOCK_SERVER_ENABLED ?? 0);

export const CONTACT_MAIL = process.env.VUE_APP_CONTACT_MAIL;

export const BASE_URL = window.location.origin;
export const BASE_URL_RAW_FILES = process.env.VUE_APP_BASE_URL_RAW_FILES;

export const NULL = 'null';
export const NULL_PLACEHOLDER = ' - ';

export const INFINITY = '∞';

export const INIT_MAP_PARAMS: MapParams = {
	dataType: FilterDataType.OBS,
	visualization: FilterVisualization.PRO,
	area: Area.REGIONS,
};

export const STROKE_BLACK_W1 = new Stroke({
	color: '#000',
	width: 1,
});

export const STROKE_TRANSPARENT_W0 = new Stroke({
	color: 'rgba(255,255,255,0)',
	width: 0,
});

export const FILL_TRANSPARENT = new Fill({
	color: 'rgba(255,255,255, 0)',
});

// It is very important not to change order in CSV_PROPS arrays, unless cols in csv is changed
export const CSV_PROPS_DATE = ['year', 'month', 'day'];
export const CSV_PROPS_LINES_BASIC = [
	'line1',
	'line2',
	'line3',
	'line4',
	'line5',
	'line6',
	'line7',
	'line8',
];
export const CSV_PROPS_LINES_PRIMARY = ['line9'];
export const CSV_PROPS_LINES_SECONDARY = ['line10', 'line11'];

export const CSV_PROPS_DEVIATION = ['deviation'];

export const LOCALE_OPTIONS = [
	new LocaleOption({
		locale: LocaleEnum.SRB_LATIN,
		shortName: 'SR',
		name: 'Srpski - Latinica',
	}),
	new LocaleOption({
		locale: LocaleEnum.SRB_CYRILLIC,
		shortName: 'СР',
		name: 'Српски - Ћирилица',
	}),
	new LocaleOption({
		locale: LocaleEnum.ENG,
		shortName: 'EN',
		name: 'English',
	}),
];

/**
 * Unit point ratio for jsPDF
 *
 *  For unit table @see (@url https://github.com/MrRio/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L791)
 */
export const JS_PDF_UNIT_POINT_RATIO = {
	pt: 1,
	mm: 72 / 25.4,
	cm: 72 / 2.54,
	in: 72,
	px: 96 / 72,
	pc: 12,
	em: 12,
	ex: 6,
};

export const JS_PDF_PAGE_FORMATS: Record<string, number[]> = {
	// Size in pt of various paper formats
	a0: [2383.94, 3370.39],
	a1: [1683.78, 2383.94],
	a2: [1190.55, 1683.78],
	a3: [841.89, 1190.55],
	a4: [595.28, 841.89],
	a5: [419.53, 595.28],
	a6: [297.64, 419.53],
	a7: [209.76, 297.64],
	a8: [147.4, 209.76],
	a9: [104.88, 147.4],
	a10: [73.7, 104.88],
	b0: [2834.65, 4008.19],
	b1: [2004.09, 2834.65],
	b2: [1417.32, 2004.09],
	b3: [1000.63, 1417.32],
	b4: [708.66, 1000.63],
	b5: [498.9, 708.66],
	b6: [354.33, 498.9],
	b7: [249.45, 354.33],
	b8: [175.75, 249.45],
	b9: [124.72, 175.75],
	b10: [87.87, 124.72],
	c0: [2599.37, 3676.54],
	c1: [1836.85, 2599.37],
	c2: [1298.27, 1836.85],
	c3: [918.43, 1298.27],
	c4: [649.13, 918.43],
	c5: [459.21, 649.13],
	c6: [323.15, 459.21],
	c7: [229.61, 323.15],
	c8: [161.57, 229.61],
	c9: [113.39, 161.57],
	c10: [79.37, 113.39],
	dl: [311.81, 623.62],
	letter: [612, 792],
	'government-letter': [576, 756],
	legal: [612, 1008],
	'junior-legal': [576, 360],
	tabloid: [792, 1224],
};

export const getAreaButtonList = () => [
	new CButtonRadioModel({
		name: FilterName.AREA,
		value: Area.SERBIA,
		label: t(`filters.area.serbia`),
	}),
	new CButtonRadioModel({
		name: FilterName.AREA,
		value: Area.REGIONS,
		label: t(`filters.area.regions`),
	}),
	new CButtonRadioModel({
		name: FilterName.AREA,
		value: Area.TOWNSHIPS,
		label: t(`filters.area.townships`),
	}),
	new CButtonRadioModel({
		name: FilterName.AREA,
		value: Area.POINT,
		label: t(`filters.area.point`),
	}),
];

export const FILTER_INIT_STATE: MapFiltersState = {
	// data & visualization
	dataVisualizationSelected: null,
	dataVisualizationList: null,
	// observation
	observationList: null,
	// projection
	projectionList: null,
	// variables
	variablesSelected: null,
	variables: null,
	variablesSelectedLabel: null,
	variablesIsDisabled: false,
	// period
	periodIsDisabled: false,
	periodSelectedLabel: null,
	// base
	basePeriodSelected: null,
	basePeriodList: null,
	// change
	changePeriodSelected: null,
	changePeriodList: null,
	// scenario
	scenarioSelected: null,
	scenarioList: null,
	// period end
	// heating
	heatingSelected: null,
	heatingList: null,
	heatingSelectedLabel: null,
	heatingIsDisabled: false,
	// season
	seasonSelected: null,
	seasonList: null,
	seasonSelectedLabel: null,
	seasonIsDisabled: false,
	// area
	areaSelected: null,
	areaList: null,
	areaSelectedLabel: null,
	areaIsDisabled: false,
};

const ensLineColor = 'rgb(255,107,107)';
const ens25LineColor = 'rgb(59,248,248)';
const ens75LineColor = 'rgb(16,187,187)';
const basicLineColor = 'rgb(30,30,30)';
const bgBarColor = 'rgb(170,170,170, 0.2)';

export const getConfigLineChartList = (): ChartDatasetConfig[] => [
	{
		type: 'line',
		label: t('mapDetails.ens'),
		order: 0,
		borderWidth: 2,
		backgroundColor: ensLineColor,
		borderColor: ensLineColor,
		props: CSV_PROPS_LINES_PRIMARY,
	},
	{
		type: 'line',
		label: t('mapDetails.ens25'),
		order: 1,
		borderWidth: 1.3,
		backgroundColor: ens25LineColor,
		borderColor: ens25LineColor,
		props: [CSV_PROPS_LINES_SECONDARY[0]],
	},
	{
		type: 'line',
		label: t('mapDetails.ens75'),
		order: 1,
		borderWidth: 1.3,
		backgroundColor: ens75LineColor,
		borderColor: ens75LineColor,
		props: [CSV_PROPS_LINES_SECONDARY[1]],
	},
	{
		type: 'line',
		label: t('mapDetails.basic'),
		order: 2,
		borderWidth: 0.2,
		backgroundColor: basicLineColor,
		borderColor: basicLineColor,
		props: CSV_PROPS_LINES_BASIC,
	},
];

export const CONFIG_BAR_LABEL = 'background';

export const CONFIG_BAR_CHART: ChartDatasetConfig = {
	type: 'line',
	label: 'background',
	order: 3,
	borderWidth: 0.2,
	backgroundColor: bgBarColor,
	borderColor: bgBarColor,
	fill: 'origin',
};

/**
 * Regex to check for UUID v4.
 * Used inside the HTTP service to check if the key is a UUID.
 */
export const UUIDV4RegEx = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const DOTS_PER_INCH = 72;
export const INCHES_PER_METRE = 39.37;
