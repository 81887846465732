export class CNavbarLink {
	public to: string | Record<string, unknown>;
	public name: string;
	public label: string;
	public type: string;

	constructor(obj: CNavbarLink) {
		this.to = obj.to;
		this.name = obj.name;
		this.label = obj.label;
		this.type = obj.type;
	}
}
