import { i18n } from '@/localization/localization';
import { JS_PDF_PAGE_FORMATS, JS_PDF_UNIT_POINT_RATIO } from '@/utils/constants.utils';
import { downloadAttachment } from '@/utils/downloadAttachment.util';
import { RoutePath } from '@/enums/RoutePath.eunm';
import { Router } from 'vue-router';
import { UrlParams } from '@/models/UrlParams.model';

/**
 *  Exported like this, so it can be used in setup() function in components or in utils functions
 */
export const { t } = i18n.global;

/**
 * logMe Is used for debugging in templates
 *
 * @param {any} item
 */
export const logMe = (item: unknown): void => console.log(item);

/**
 * Generates random number from 0 to 1000 000, and it's used for generating id for components.
 */
export const generateId = (): number => Math.floor(Math.random() * 1000000);

/**
 * Debounce call of callback function.
 *
 * @param cb - callback function
 * @param wait - time to wait in ms
 */
export function debounce<T extends (...args: any) => any>(cb: T, wait = 200) {
	let h = 0;
	const callable = (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => cb(...args), wait);
	};
	return <T>(<any>callable);
}

/**
 * Filters out elements that are repeating in list & returns only uniq values.
 *
 * @param array
 */
export function getUniqValues<T>(array: T[]): T[] {
	return [...new Set(array)];
}

/**
 * Gets image from assets for :src in <img> element in template.
 *
 * @param imgName
 */
export function getImageFormAssets(imgName: string): any {
	// eslint-disable-next-line global-require,import/no-dynamic-require
	return require(`@/assets/images/${imgName}`);
}

/**
 * Capitalizes first letter of word.
 * If word is falsy it will return fallbackWord.
 *
 * (eg.)
 * (word, fallbackWord) -> returnVal
 * ("ruki", "") -> "Ruki"
 * ("ruki", "fallback word") -> "Ruki"
 * ("", "fallback word") -> "fallback word"
 * ("", "") -> ""
 *
 * @param word
 * @param fallbackWord
 *
 */
export const capitalizeWord = (word: string, fallbackWord = ''): string => {
	if (!word) {
		return fallbackWord;
	}

	return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
};

/**
 * Transforms CamelCaseLetter to snake_case_letter
 *
 * @param str
 */
export const convertCamelToSnakeCase = (str: string): string => {
	const searchCapitals = /[A-Z]/g;
	const transformLetter = (letter: string) => `_${letter.toLowerCase()}`;
	return str.replace(searchCapitals, transformLetter);
};

/**
 * Transforms Object with camelCase keys to snake_case key
 * It is used to transform request object to params
 *
 * (eg.) camelCaseObj => snake_case_obj
 * camelCaseObj:
 {
   "dataType": "dataType",
   "scenarioUuid": "scenarioUuid",
   "changePeriodUuid": "changePeriodUuid",
   "heating": "heating",
  }
 * snake_case_obj:
 {
   "data_type": "dataType",
   "scenario_uuid": "scenarioUuid",
   "change_period_uuid": "changePeriodUuid",
   "heating": "heating",
  }
 *
 * @param obj
 */
export const convertCamelToSnakeCaseObjectKeys = (
	obj: Record<string, unknown>
): Record<string, unknown> => {
	const newObj: Record<string, unknown> = {};
	let snakeCaseKey = '';
	Object.entries(obj).forEach(([key, value]) => {
		snakeCaseKey = convertCamelToSnakeCase(key);
		newObj[snakeCaseKey] = value;
	});
	return newObj;
};

export const clone = <T = Record<string, unknown>>(obj: T): T => {
	return JSON.parse(JSON.stringify(obj)) as T;
};

/**
 * Converts snake_case word to CamelCase word
 *
 * @param str
 */
export function convertSnakeToCamelCase({
	str,
	separator = '',
}: {
	str: string;
	separator?: string;
}): string {
	return str
		.split('_')
		.map((word) => capitalizeWord(word))
		.join(separator);
}

/**
 * Check if the `value` is an empty object, array, map or set
 * @param value
 * @return {boolean}
 * * @example
 *
 * falsy(null);
 * // => true
 *
 * falsy([]);
 * // => true
 *
 * falsy({});
 * // => true
 *
 * falsy(1);
 * // => false
 *
 * falsy([1, 2, 3]);
 * // => false
 *
 * falsy({ 'a': 1 });
 * // => false
 */
export function falsy(
	value: Record<any, any> | Set<any> | Map<any, any> | Array<any> | string
): boolean {
	const mapTag = '[object Map]';
	const setTag = '[object Set]';

	if (value === null || value === undefined) {
		return true;
	}
	if (Array.isArray(value) || typeof value === 'string') {
		return !value.length;
	}
	const objType = value.toString();
	if (objType === mapTag || objType === setTag) {
		return !value.size;
	}

	// Check if object is empty
	// eslint-disable-next-line
	for (const key in value) {
		return false;
	}

	return true;
}

/**
 * Gets a filename from API headers or returns null if fn didn't find it.
 *
 * @param headers
 */
export function getFilenameFromHeaders(headers: Record<string, any>): string {
	const { contentDisposition } = headers;
	const filename = (contentDisposition as string)
		?.split(' ')
		?.find((str) => str.includes('filename'))
		?.replace('filename=', '');

	return filename || null;
}

/**
 * Converts `csv` file where
 * first line is `metadata`,
 * second line is list of props - witch can be renamed if you pass `propNames` array,
 * other lines are `data`.
 *
 * Fn returns array of objects with props and values.
 *
 * eg.
 *
 * `csv content`
 * boruo: BORSKI_UPRAVNI_OKRUG/BORSKI_UPRAVNI_OKRUG - tas(C) [odstupanje]
 * godina,mesec,dan,vre
 * 1950,06,30,2.1349
 * 1951,06,30,1.8228
 * 1952,06,30,1.9818
 * ...
 *
 * `function usage`
 * convertCSVTextToArray({ csvTxt: csv, propNames: ['year, month, 'day', 'value']})
 * // returns [
 *  { year: '1950', month: '06', day: '30', value: '2.1349' },
 *  { year: '1951', month: '06', day: '30', value: '1.8228' },
 *  { year: '1952', month: '06', day: '30', value: '1.9818' },
 *  ...
 * ]
 *
 * @param csvTxt
 * @param propNames
 */
export function convertCSVTextToArray<T>({
	csvTxt,
	propNames = [],
}: {
	csvTxt: string;
	propNames?: string[];
}): Array<T> {
	const csvLines = csvTxt.split('\n');
	// [meta, props]
	const [, props] = csvLines;
	const propsArr = props.split(',');

	let valsArr: any[] = [];
	const arr: T[] = [];
	let obj: Record<string, unknown> = {};

	// Going from i = 2 because i = 0 => meta, i = 1 => props
	for (let i = 2; i < csvLines.length && !!csvLines[i]; i += 1) {
		obj = {};
		valsArr = csvLines[i].split(',');

		// eslint-disable-next-line no-loop-func
		propsArr.forEach((prop, j) => {
			obj[propNames[j] || prop] = valsArr[j];
		});

		arr.push(obj as T);
	}

	return arr;
}

export function cloneCanvasContext(ctxChart: CanvasRenderingContext2D): CanvasRenderingContext2D {
	const { width, height } = ctxChart.canvas;

	const imageData = ctxChart.getImageData(0, 0, width, height);
	const canvas = document.createElement('canvas') as HTMLCanvasElement;
	const ctx = canvas.getContext('2d');
	ctx.canvas.width = width;
	ctx.canvas.height = height;
	ctx.putImageData(imageData, 0, 0);

	return ctx;
}

type Unit = 'pt' | 'mm' | 'cm' | 'in' | 'px' | 'pc' | 'em' | 'ex';

/**
 *  Converts jsPDF point (pt) to specific unit.
 *  For unit table @see (@url https://github.com/MrRio/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L791)
 *
 * @param points
 * @param unit
 */
export function convertPointsToUnit(points: number, unit: Unit): number {
	return points * JS_PDF_UNIT_POINT_RATIO[unit];
}

/**
 * Converts unit to jsPDF point (pt)
 * For unit table @see (@url https://github.com/MrRio/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L791)
 *
 * @param quantity
 * @param unit
 */
export function convertUnitToPoints(quantity: number, unit: Unit): number {
	return quantity / JS_PDF_UNIT_POINT_RATIO[unit];
}

/**
 * Adds background color to canvas and downloads the image.
 * (eg.) This fn is used to download image with white background.
 *
 * @param canvas
 * @param downloadName
 */
export function downloadWithPattern({
	canvas,
	downloadName,
}: {
	canvas: HTMLCanvasElement;
	downloadName: string;
}): void {
  const ctx = cloneCanvasContext(canvas.getContext('2d'));

  ctx.globalCompositeOperation = 'destination-over';
  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  const download = (blob: Blob) => downloadAttachment(blob, `${downloadName}.png`);
  ctx.canvas.toBlob(download);
}

/**
 *
 * Returns most convenient PDF format for given width & height
 *
 * @param width
 * @param height
 * @param unit
 */
export const getPDFFormat = ({
	width,
	height,
	unit = 'px',
}: {
	width: number;
	height: number;
	unit?: Unit;
}): string => {
	const widthPt = convertUnitToPoints(width, unit);
	const heightPt = convertUnitToPoints(height, unit);

	let minDiff: number = Number.MAX_SAFE_INTEGER;
	let diff: number;
	let widthDiff: number;
	let heightDiff: number;

	let pdfFormat: string = null;

	const entries = Object.entries(JS_PDF_PAGE_FORMATS);

	for (let i = 0; i < entries.length; i += 1) {
		const [format, [widthPDF, heightPDF]]: [string, number[]] = entries[i];

		widthDiff = widthPDF - widthPt;
		heightDiff = heightPDF - heightPt;

		if (widthDiff > 0 && heightDiff > 0) {
			diff = heightDiff + widthDiff;
			if (diff < minDiff) {
				minDiff = diff;
				pdfFormat = format;
			}
		}
	}

	return pdfFormat;
};

/**
 * Returns range of number including start & end
 * And if end is equal or smaller then start it will return empty string.
 *
 * (eg.)
 * range({ start = 3, end = 10 }) => [3, 4, 5, 6, 7, 8, 9, 10]
 *
 * @param start - by default 0
 * @param end
 */
export function range({ start = 0, end = 0 }: { start?: number; end: number }): number[] {
	if (end < start) {
		return [];
	}
	const n = end - start + 1;
	return new Array(n).fill(0).map((_, i) => i + start);
}

/**
 * Adds 'separator' on every 'step' digits
 *
 * (eg.)
 * num = 1256000
 * step = 3
 * separator = ','
 *
 * return '1,256,000'
 *
 * @param num
 * @param step
 * @param separator
 */
export function formatNumber({
	num,
	step = 3,
	separator = ' ',
}: {
	num: number;
	step?: number;
	separator?: string;
}): string {
	const numArr = num.toString().split('');
	for (let i = numArr.length - step; i > 0; i -= step) {
		numArr.splice(i, 0, separator);
	}
	return numArr.join('');
}

/**
 * Rounds number to a certain number of decimal places.
 *
 * Rounding number manually because toFixed alone has some shady outputs in some situations.
 *
 * If you want to know more about shady outputs of toFixed
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#description}
 *
 * @param {number} number
 * @param {number} decimals
 *
 * @return {number}
 */
export function roundNumber({ num, decimals }: { num: number; decimals: number }) {
	const pow = 10 ** decimals;
	const value = Math.round(num * pow) / pow;
	return Number(value.toFixed(decimals));
}

/**
 * Scrolls parent view, so element could be visible.
 *
 * @param query
 * @param options
 */
export function scrollIntoView(
	query: string,
	options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' }
): void {
	const ele = document.querySelector(query);
	// eslint-disable-next-line no-unused-expressions
	ele?.scrollIntoView(options);
}

/**
 * Return current query params like object
 */
export function getCurrentParams(): UrlParams {
	const urlParams = new URLSearchParams(window.location.search);
	const currentParams: UrlParams = {};
	let param = null;

	[...urlParams.entries()].forEach(([key, val]) => {
		param = currentParams[key];
		if (param) {
			currentParams[key] = [...(Array.isArray(param) ? param : [param]), val];
		} else {
			currentParams[key] = val;
		}
	});

	return currentParams;
}

/**
 * Merges passed `params` with `currentParams` and updates URL
 *
 * @param path
 * @param params
 * @param router
 */
export function mergeUrlParams({
	router,
	params,
	path,
}: {
	router: Router;
	params: UrlParams;
	path: RoutePath;
}): void {
	const currentParams = getCurrentParams();

	const { href } = router.resolve({
		path,
		query: { ...currentParams, ...params },
	});

	window.history.pushState({}, null, href);
}
