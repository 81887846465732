
import { defineComponent } from 'vue';
import CCollapse from '@/components/shared/CCollapse.vue';
import { getImageFormAssets, scrollIntoView, t } from '@/utils/utils';
import { useMeta } from 'vue-meta';

enum ClimateIndexId {
	I1 = 'climate-index-1',
	I2 = 'climate-index-2',
	I3 = 'climate-index-3',
	I4 = 'climate-index-4',
	I5 = 'climate-index-5',
	I6 = 'climate-index-6',
	I7 = 'climate-index-7',
	I8 = 'climate-index-8',
	I9 = 'climate-index-9',
	I10 = 'climate-index-10',
	I11 = 'climate-index-11',
	I12 = 'climate-index-12',
	I13 = 'climate-index-13',
	I14 = 'climate-index-14',
	I15 = 'climate-index-15',
	I16 = 'climate-index-16',
	I17 = 'climate-index-17',
	I18 = 'climate-index-18',
	I19 = 'climate-index-19',
	I20 = 'climate-index-20',
	I21 = 'climate-index-21',
	I22 = 'climate-index-22',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'InfoClimateIndexes',
	components: { CCollapse },
	setup() {
		useMeta({
			title: t('tab.info.climateIndexes'),
		})
		return {
			ClimateIndexId,
			getImageFormAssets,
		};
	},
	computed: {
		currentIndexId(): string {
			return this.$route.hash.substring(1);
		},
	},
	mounted() {
		if (this.currentIndexId) {
			scrollIntoView(`#${this.currentIndexId}`);
		}
	},
	methods: {
		setIndexId(id: ClimateIndexId) {
			window.history.pushState({}, null, `${this.$route.path}#${id}`);
		},
		removeIndexId() {
			window.history.pushState({}, null, this.$route.path);
		},
		handleIndexToggle(id: ClimateIndexId, isOpen: boolean): void {
			if (isOpen) {
				this.setIndexId(id);
			} else {
				this.removeIndexId();
			}
		},
		toOpenIndex(id: ClimateIndexId): boolean {
			return this.currentIndexId === id;
		},
	},
});
