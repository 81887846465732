export function downloadAttachment(data: any, name: string): void {
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(data, name);
  } else {
    const blob = new Blob([data], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }
}
