export enum QuestionId {
  Q1 = 'question-1',
  Q2 = 'question-2',
  Q3 = 'question-3',
  Q4 = 'question-4',
  Q5 = 'question-5',
  Q6 = 'question-6',
  Q7 = 'question-7',
  Q8 = 'question-8',
  Q9 = 'question-9',
  Q10 = 'question-10',
  Q11 = 'question-11',
  Q12 = 'question-12',
  Q13 = 'question-13',
  Q14 = 'question-14',
  Q15 = 'question-15',
  Q16 = 'question-16',
  Q17 = 'question-17',
  Q18 = 'question-18',
  Q19 = 'question-19',
  Q20 = 'question-20',
  Q21 = 'question-21',
  Q22 = 'question-22',
  Q23 = 'question-23',
  Q24 = 'question-24',
  Q25 = 'question-25',
}
