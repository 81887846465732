// [ 'Abbreviation', 'Municipalitie', 'Abbreviation', 'Municipalitie', 'Abbreviation', 'Municipalitie']
export const townshipTableLat = [
	['ada', 'ADA', 'lazere', 'LAZAREVAC', 'srbica', 'SRBICA'],
	['aleksa‌‌', 'ALEKSANDROVAC‌‌', 'lebane‌ ‌', 'LEBANE‌ ‌', 'srbobr‌ ‌', 'SRBOBRAN‌ ‌', ''],
	[
		'aleksi‌ ‌',
		'ALEKSINAC‌ ‌',
		'leposa‌ ‌',
		'LEPOSAVIĆ‌ ‌',
		'smitro‌ ‌',
		'SREMSKA‌ ‌MITROVICA‌ ‌',
		'',
	],
	[
		'alibun‌ ‌',
		'ALIBUNAR‌ ‌',
		'leskov‌ ‌',
		'LESKOVAC‌ ‌',
		'skarlo‌ ‌',
		'SREMSKI‌ ‌KARLOVCI‌ ‌',
		'',
	],
	['apatin‌ ‌', 'APATIN‌ ‌', 'liplja‌ ‌', 'LIPLJAN‌ ‌', 'spazov‌ ‌', 'STARA‌ ‌PAZOVA‌ ‌', ''],
	['arandj‌ ‌', 'ARANĐELOVAC‌ ‌', 'ljig‌ ‌', 'LJIG‌ ‌', 'stgrad‌ ‌', 'STARI‌ ‌GRAD‌ ‌', ''],
	['arilje‌ ‌', 'ARILJE‌ ‌', 'ljubov‌ ‌', 'LJUBOVIJA‌ ‌', 'suboti‌ ‌', 'SUBOTICA‌ ‌', ''],
	['babusn‌ ‌', 'BABUŠNICA‌ ‌', 'loznic‌ ‌', 'LOZNICA‌ ‌', 'surdul‌ ‌', 'SURDULICA‌ ‌', ''],
	['bbasta‌ ‌', 'BAJINA‌ ‌BAŠTA‌ ‌', 'lucani‌ ‌', 'LUČANI‌ ‌', 'surcin‌ ‌', 'SURČIN‌ ‌', ''],
	['baraje‌ ‌', 'BARAJEVO‌ ‌', 'majdan‌ ‌', 'MAJDANPEK‌ ‌', 'sureka‌ ‌', 'SUVA‌ ‌REKA‌ ‌', ''],
	['batoci‌ ‌', 'BATOČINA‌ ‌', 'maidjo‌ ‌', 'MALI‌ ‌IĐOŠ‌ ‌', 'svilaj‌ ‌', 'SVILAJNAC‌ ‌', ''],
	['bac‌ ‌', 'BAČ‌ ‌', 'mazvor‌ ‌', 'MALI‌ ‌ZVORNIK‌ ‌', 'svrlji‌ ‌', 'SVRLJIG‌ ‌', ''],
	[
		'bapal‌ ‌',
		'BAČKA‌ ‌PALANKA‌ ‌',
		'macrni‌ ‌',
		'MALO‌ ‌CRNIĆE‌ ‌',
		'temeri‌ ‌',
		'TEMERIN‌ ‌',
		'',
	],
	['batop‌ ‌', 'BAČKA‌ ‌TOPOLA‌ ‌', 'medija‌ ‌', 'MEDIJANA‌ ‌', 'titel‌ ‌', 'TITEL‌ ‌', ''],
	['bapet‌ ‌', 'BAČKI‌ ‌PETROVAC‌ ‌', 'medve‌ ‌', 'MEDVEĐA‌ ‌', 'topola‌ ‌', 'TOPOLA‌ ‌', ''],
	['becrv‌ ‌', 'BELA‌ ‌CRKVA‌ ‌', 'merosi‌ ‌', 'MEROŠINA‌ ‌', 'trgovi‌ ‌', 'TRGOVIŠTE‌ ‌', ''],
	['bepal‌ ‌', 'BELA‌ ‌PALANKA‌ ‌', 'mionic‌ ‌', 'MIONICA‌ ‌', 'trsten‌ ‌', 'TRSTENIK‌ ‌', ''],
	['beocin‌ ‌', 'BEOČIN‌ ‌', 'mladen‌ ‌', 'MLADENOVAC‌ ‌', 'tutin‌ ‌', 'TUTIN‌ ‌', ''],
	['becej‌ ‌', 'BEČEJ‌ ‌', 'negoti‌ ‌', 'NEGOTIN‌ ‌', 'ub‌ ‌', 'UB‌ ‌', ''],
	['blace‌ ‌', 'BLACE‌ ‌', 'nbanja‌ ‌', 'NIŠKA‌ ‌BANJA‌ ‌', 'urosev‌ ‌', 'UROŠEVAC‌ ‌', ''],
	['bogati‌ ‌', 'BOGATIĆ‌ ‌', 'ncrnja‌ ‌', 'NOVA‌ ‌CRNJA‌ ‌', 'uzice‌ ‌', 'UŽICE‌ ‌', ''],
	['bojnik‌ ‌', 'BOJNIK‌ ‌', 'nvaros‌ ‌', 'NOVA‌ ‌VAROŠ‌ ‌', 'valjev‌ ‌', 'VALJEVO‌ ‌', ''],
	['boljev‌ ‌', 'BOLJEVAC‌ ‌', 'nbeogr‌ ‌', 'NOVI‌ ‌BEOGRAD‌ ‌', 'varvar‌ ‌', 'VARVARIN‌ ‌', ''],
	['bor‌ ‌', 'BOR‌ ‌', 'nbecej‌ ‌', 'NOVI‌ ‌BEČEJ‌ ‌', 'vplana‌ ‌', 'VELIKA‌ ‌PLANA‌ ‌', ''],
	[
		'bosile‌ ‌',
		'BOSILEGRAD‌ ‌',
		'nkneze‌ ‌',
		'NOVI‌ ‌KNEŽEVAC‌ ‌',
		'vgradi‌ ‌',
		'VELIKO‌ ‌GRADIŠTE‌ ‌',
		'',
	],
	['brus‌ ‌', 'BRUS‌ ‌', 'npazar‌ ‌', 'NOVI‌ ‌PAZAR‌ ‌', 'vitina‌ ‌', 'VITINA‌ ‌', ''],
	['bujano‌ ‌', 'BUJANOVAC‌ ‌', 'nsad‌ ‌', 'NOVI‌ ‌SAD‌ ‌', 'vladim‌ ‌', 'VLADIMIRCI‌ ‌', ''],
	[
		'ctrava‌ ‌',
		'CRNA‌ ‌TRAVA‌ ‌',
		'nbrdo‌ ‌',
		'NOVO‌ ‌BRDO‌ ‌',
		'vlahan‌ ‌',
		'VLADIČIN‌ ‌HAN‌ ‌',
		'',
	],
	['crkrst‌ ‌', 'CRVENI‌ ‌KRST‌ ‌', 'obilic‌ ‌', 'OBILIĆ‌ ‌', 'vlasot‌ ‌', 'VLASOTINCE‌ ‌', ''],
	['despot‌ ‌', 'DESPOTOVAC‌ ‌', 'obreno‌ ‌', 'OBRENOVAC‌ ‌', 'vozdov‌ ‌', 'VOŽDOVAC‌ ‌', ''],
	['decani‌ ‌', 'DEČANI‌ ‌', 'odzaci‌ ‌', 'ODŽACI‌ ‌', 'vranje‌ ‌', 'VRANJE‌ ‌', ''],
	['dimitr‌ ‌', 'DIMITROVGRAD‌ ‌', 'opovo‌ ‌', 'OPOVO‌ ‌', 'vranjb‌ ‌', 'VRANJSKA‌ ‌BANJA‌ ‌', ''],
	['doljev‌ ‌', 'DOLJEVAC‌ ‌', 'orahov‌ ‌', 'ORAHOVAC‌ ‌', 'vracar‌ ‌', 'VRAČAR‌ ‌', ''],
	['gahan‌ ‌', 'GADŽIN‌ ‌HAN‌ ‌', 'osecin‌ ‌', 'OSEČINA‌ ‌', 'vrbas‌ ‌', 'VRBAS‌ ‌', ''],
	[
		'glogov‌ ‌',
		'GLOGOVAC‌ ‌',
		'palini‌ ‌',
		'PALILULA‌ ‌(NIŠ)‌ ‌',
		'vrnjab‌ ‌',
		'VRNJAČKA‌ ‌BANJA‌ ‌',
		'',
	],
	['gnjlan‌ ‌', 'GNJILANE‌ ‌', 'palibe‌ ‌', 'PALILULA‌ ‌(BEOGRAD)‌ ‌', 'vrsac‌ ‌', 'VRŠAC‌ ‌', ''],
	['goluba‌ ‌', 'GOLUBAC‌ ‌', 'pantel‌ ‌', 'PANTELEJ‌ ‌', 'vucitr‌ ‌', 'VUČITRN‌ ‌', ''],
	['gora‌ ‌', 'GORA‌ ‌', 'pancev‌ ‌', 'PANČEVO‌ ‌', 'zajeca‌ ‌', 'ZAJEČAR‌ ‌', ''],
	['gomila‌ ‌', 'GORNJI‌ ‌MILANOVAC‌ ‌', 'paraci‌ ‌', 'PARAĆIN‌ ‌', 'zemun‌ ‌', 'ZEMUN‌ ‌', ''],
	[
		'grocka‌ ‌',
		'GROCKA‌ ‌',
		'penaml‌ ‌',
		'PETROVAC‌ ‌NA‌ ‌MLAVI‌ ‌',
		'zrenja‌ ‌',
		'ZRENJANIN‌ ‌',
		'',
	],
	['indji‌ ‌', 'INĐIJA‌ ‌', 'pec‌ ‌', 'PEĆ‌ ‌', 'zubinp‌ ‌', 'ZUBIN‌ ‌POTOK‌ ‌', ''],
	['irig‌ ‌', 'IRIG‌ ‌', 'pecinc‌ ‌', 'PEĆINCI‌ ‌', 'zvezda‌ ‌', 'ZVEZDARA‌ ‌', ''],
	['istok‌ ‌', 'ISTOK‌ ‌', 'pirot‌ ‌', 'PIROT‌ ‌', 'zvecan‌ ‌', 'ZVEČAN‌ ‌', ''],
	['ivanji‌ ‌', 'IVANJICA‌ ‌', 'pladis‌ ‌', 'PLANDIŠTE‌ ‌', 'ciceva‌ ‌', 'ĆIĆEVAC‌ ‌', ''],
	['jagodi‌ ‌', 'JAGODINA‌ ‌', 'poduje‌ ‌', 'PODUJEVO‌ ‌', 'cupri‌ ‌', 'ĆUPRIJA‌ ‌', ''],
	['kanjiz‌ ‌', 'KANJIŽA‌ ‌', 'pozare‌ ‌', 'POŽAREVAC‌ ‌', 'cajeti‌ ‌', 'ČAJETINA‌ ‌', ''],
	['kacani‌ ‌', 'KAČANIK‌ ‌', 'pozega‌ ‌', 'POŽEGA‌ ‌', 'cacak‌ ‌', 'ČAČAK‌ ‌', ''],
	['kikind‌ ‌', 'KIKINDA‌ ‌', 'presev‌ ‌', 'PREŠEVO‌ ‌', 'coka‌ ‌', 'ČOKA‌ ‌', ''],
	['kladov‌ ‌', 'KLADOVO‌ ‌', 'priboj‌ ‌', 'PRIBOJ‌ ‌', 'cukari‌ ‌', 'ČUKARICA‌ ‌', ''],
	['klina‌ ‌', 'KLINA‌ ‌', 'prijep‌ ‌', 'PRIJEPOLJE‌ ‌', 'djakov‌ ‌', 'ĐAKOVICA‌ ‌', ''],
	['knic‌ ‌', 'KNIĆ‌ ‌', 'prizre‌ ‌', 'PRIZREN‌ ‌', 'sabac‌ ‌', 'ŠABAC‌ ‌', ''],
	['knjaze‌ ‌', 'KNJAŽEVAC‌ ‌', 'pristi‌ ‌', 'PRIŠTINA‌ ‌', 'sid‌ ‌', 'ŠID‌ ‌', ''],
	['kocelj‌ ‌', 'KOCELJEVA‌ ‌', 'prokup‌ ‌', 'PROKUPLJE‌ ‌', 'stimlj‌ ‌', 'ŠTIMLJE‌ ‌', ''],
	['kosjer‌ ‌', 'KOSJERIĆ‌ ‌', 'rakovi‌ ‌', 'RAKOVICA‌ ‌', 'strpce‌ ‌', 'ŠTRPCE‌ ‌', ''],
	['kpolje‌ ‌', 'KOSOVO‌ ‌POLJE‌ ‌', 'raca‌ ‌', 'RAČA‌ ‌', 'zabalj‌ ‌', 'ŽABALJ‌ ‌', ''],
	['kkamen‌ ‌', 'KOSOVSKA‌ ‌KAMENICA‌ ‌', 'raska‌ ‌', 'RAŠKA‌ ‌', 'zabari‌ ‌', 'ŽABARI‌ ‌', ''],
	[
		'kmitro‌ ‌',
		'KOSOVSKA‌ ‌MITROVICA‌ ‌',
		'razanj‌ ‌',
		'RAŽANJ‌ ‌',
		'zagubc‌ ‌',
		'ŽAGUBICA‌ ‌',
		'',
	],
	['kostol‌ ‌', 'KOSTOLAC‌ ‌', 'rekova‌ ‌', 'REKOVAC‌ ‌', 'zitist‌ ‌', 'ŽITIŠTE‌ ‌', ''],
	['kovaci‌ ‌', 'KOVAČICA‌ ‌', 'ruma‌ ‌', 'RUMA‌ ‌', 'zitora‌ ‌', 'ŽITORAĐA‌ ‌', ''],
	['kovin‌ ‌', 'KOVIN‌ ‌', 'svenac‌ ‌', 'SAVSKI‌ ‌VENAC‌ ‌', '‌', '‌', ''],
	['kraguj‌ ‌', 'KRAGUJEVAC‌ ‌', 'senta‌ ‌', 'SENTA‌ ‌', '‌', '‌', ''],
	['kralje‌ ‌', 'KRALJEVO‌ ‌', 'sevojn‌ ‌', 'SEVOJNO‌ ‌', '‌', '‌', ''],
	['krupa‌ ‌', 'KRUPANJ‌ ‌', 'secanj‌ ‌', 'SEČANJ‌ ‌', '‌', '‌', ''],
	['krusev‌ ‌', 'KRUŠEVAC‌ ‌', 'sjenic‌ ‌', 'SJENICA‌ ‌', '‌', '‌', ''],
	['kula‌ ‌', 'KULA‌ ‌', 'smeder‌ ‌', 'SMEDEREVO‌ ‌', ' ‌', ' ‌', ''],
	['kursum‌ ‌', 'KURŠUMLIJA‌ ‌', 'smepal‌ ‌', 'SMEDEREVSKA‌ ‌PALANKA‌ ‌', ' ‌', ' ‌', ''],
	['kucevo‌ ‌', 'KUČEVO‌ ‌', 'sokoba‌ ‌', 'SOKOBANJA‌ ‌', ' ‌', ' ‌', ''],
	['lajkov‌ ‌', 'LAJKOVAC‌ ‌', 'sombor‌ ‌', 'SOMBOR‌ ‌', ' ‌', ' ‌', ''],
	['lapovo‌ ‌', 'LAPOVO‌ ‌', 'sopot‌ ‌', 'SOPOT‌ ‌', ' ‌', ' ‌', ''],
];
