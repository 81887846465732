import { createApp } from 'vue';
import './registerServiceWorker';
import { IS_ENV_DEVELOPMENT, IS_MOCKSERVER_ENABLED } from '@/utils/constants.utils';

/**
 *  Want to learn more about vue-meta
 *  @see Docs (@link https://github.com/nuxt/vue-meta/tree/next)
 */
import { createMetaManager, plugin as vueMetaPlugin } from "vue-meta";

/**
 *  Want to learn more about FontAwesomeIcon
 *  @see Docs (@link https://github.com/FortAwesome/vue-fontawesome)
 */
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { icons } from '@/icons/font-awesome';

/**
 *  Want to learn more about VTooltip
 *  @see Docs (@link https://v-tooltip.netlify.app/)
 *  @see Discussion (@link https://github.com/Akryum/v-tooltip/discussions/603)
 *  @see Issue (@link https://github.com/Akryum/v-tooltip/issues/545)
 */
import VTooltip from 'v-tooltip';

/**
 *  Want to learn more about v-wave
 *  @see Docs (@link https://github.com/justintaddei/v-wave#usage)
 */
import VWave from 'v-wave';

/**
 *  Want to learn more about Mirage (Mock Server)
 *  @see Docs (@link https://miragejs.com/)
 *  @see Tutorial (@link https://css-tricks.com/dont-wait-mock-the-api/)
 */
import createMockServer from './api/mock/index.mock';

/**
 *  Want to learn more about vue-i18n
 *  @see Docs (@link https://vue-i18n.intlify.dev/)
 */
import { i18n } from './localization/localization';

/**
 *  Want to learn more about vue-router
 *  @see Docs (@link https://next.router.vuejs.org/)
 */
import router from './router';

import App from './App.vue';

library.add(...icons);

if (IS_ENV_DEVELOPMENT && IS_MOCKSERVER_ENABLED) {
	createMockServer();
}

const app = createApp(App);
app.use(vueMetaPlugin);
app.use(createMetaManager());

app.use(i18n);
app.use(router);
app.use(VTooltip, {
	disposeTimeout: 200, // Same as default value for tooltip.delay.show
});
app.use(VWave);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('FontAwesomeLayers', FontAwesomeLayers);
app.mount('#app');
