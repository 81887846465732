
import { defineComponent, PropType, ref, watch } from 'vue';
import { DropdownPlacement } from '@/enums/DropdownPlacement.enum';

enum ComponentEvent {
	CLOSE = 'close',
	CHANGE_VISIBILITY = 'changeVisibility',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CDropdown',
	props: {
		toShow: {
			required: false,
			type: Boolean,
			default: false,
		},
		placement: {
			required: false,
			type: String as PropType<DropdownPlacement>,
			default: () => DropdownPlacement.BOTTOM,
		},
		hideAnimation: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const visibility = ref(props.toShow);

		const toShowSource = () => props.toShow;
		const updateVisibility = (toShow: boolean) => {
			visibility.value = toShow;
		};
		watch(toShowSource, updateVisibility);

		return {
			visibility,
		};
	},
	data() {
		return {
			styleContent: {},
			styleContainer: {},
		};
	},
	watch: {
		visibility: {
			immediate: true,
			handler(toShowNewVal) {
				if (toShowNewVal) {
					document.addEventListener('click', this.closeDropdown);
				} else {
					document.removeEventListener('click', this.closeDropdown);
				}
			},
		},
	},
	beforeUnmount() {
		document.removeEventListener('click', this.closeDropdown);
	},
	mounted() {
		const btnEle = this.$refs.trigger as HTMLElement;
		const height = btnEle?.offsetHeight;

		switch (this.placement) {
			case DropdownPlacement.LEFT: {
				this.styleContent = {
					top: `-${height}px`,
					right: '100%',
					'margin-right': '8px',
				};
				break;
			}
			case DropdownPlacement.RIGHT: {
				this.styleContent = {
					top: `-${height}px`,
					left: '100%',
					'margin-left': '8px',
				};
				break;
			}
			// Bottom
			default: {
				this.styleContent = {
					'margin-top': '8px',
					right: 0,
				};
				break;
			}
		}
	},
	methods: {
		toggleVisibility(): void {
			this.visibility = !this.visibility;
			this.$emit(ComponentEvent.CHANGE_VISIBILITY, this.visibility);
		},
		closeDropdown(event: MouseEvent): void {
			const dropdownEle = this.$refs.dropdown as HTMLElement;
			if (dropdownEle?.contains((event as any).target)) {
				return;
			}
			this.visibility = false;
			this.$emit(ComponentEvent.CLOSE);
			this.$emit(ComponentEvent.CHANGE_VISIBILITY, this.visibility);
		},
	},
});
