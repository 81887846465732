import {
	faCheck,
	faChevronDown,
	faChevronLeft,
	faDownload,
	faMapMarker,
	faMapMarkerAlt,
	faMinus,
	faPlus,
	faTimes,
	faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

export const icons = [
	// solid
	faChevronDown,
	faChevronLeft,
	faCheck,
	faPlus,
	faMinus,
	faMapMarker,
	faMapMarkerAlt,
	faDownload,
	faTimes,
	faInfoCircle,

	// regular
	faEnvelope,
];
