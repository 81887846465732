interface Nav {
	home: string;
	map: string;
	about: string;
	rawFiles: string;
	contact: string;
	info: string;
}

interface Tab {
	info: {
		qAndA: string;
		climateIndexes: string;
		fileNames: string;
		citingData: string;
	};
}

interface Title {
	title: string;
}

interface TitleShort {
	titleShort: string;
}

interface Info {
	info: string;
}

interface Word {
	for: string;
	to: string;
	and: string;
	result: string;
	results: string;
	note: string;
	mail: string;
	here: string;
	netcdf: string;
	ncdump: string;
	csv: string;
	csvLong: string;
	spreadsheet: string;
	lon: string;
	lat: string;
	search: string;
	send: string;
	name: string;
	email: string;
	address: string;
	title: string;
	message: string;
	definition: string;
	page: string;
	days: string;
}

interface Message {
	allOptionsAreRequired: string;
	emptyOptions: string;
	requiredOption: string;
	requiredFiled: string;
	successfulAction: string;
	unsuccessfulAction: string;
	unusedLegendItem: string;
	exampleOfUsage: string;
	developedBy: string;
	numberOfEvents: string;
}

interface Observation extends Title, Info {}

interface Projection extends Title, Info {}

interface FilterDataVisualisation extends Title {
	observation: Observation;
	projection: Projection;
	obspro: string;
	obsvre: string;
	modpro: string;
	modvre: string;
}

interface VariableLocal extends Title, Info {
	baseTitle: string;
	indexTitle: string;
	infoRawFiles: string;

	tas: string;
	tasmax: string;
	tasmin: string;
	pr: string;
	fd: string;
	id: string;
	td: string;
	tr: string;
	wsdi: string;
	rr20: string;
	rr30: string;
	rr50: string;
	cdd: string;
	gsl5: string;
	gsl10: string;
	spei: string;
	htc: string;
	eq: string;
	fai: string;
	hwfi: string;
	rx1d: string;
	rx5d: string;
	su: string;
	txge35: string;
	hwfid: string;
	hwdi: string;
	hwdid: string;
	cwd: string;
	spei6a: string;

	tasTooltip: string;
	tasmaxTooltip: string;
	tasminTooltip: string;
	prTooltip: string;
	fdTooltip: string;
	idTooltip: string;
	tdTooltip: string;
	trTooltip: string;
	wsdiTooltip: string;
	rr20Tooltip: string;
	rr30Tooltip: string;
	rr50Tooltip: string;
	cddTooltip: string;
	gsl5Tooltip: string;
	gsl10Tooltip: string;
	speiTooltip: string;
	htcTooltip: string;
	eqTooltip: string;
	faiTooltip: string;
	hwfiTooltip: string;
	rx1dTooltip: string;
	rx5dTooltip: string;
	suTooltip: string;
	txge35Tooltip: string;
	hwfidTooltip: string;
	hwdiTooltip: string;
	hwdidTooltip: string;
	cwdTooltip: string;
	spei6aTooltip: string;

	tasTitle: string;
	tasmaxTitle: string;
	tasminTitle: string;
	prTitle: string;
	fdTitle: string;
	idTitle: string;
	tdTitle: string;
	trTitle: string;
	wsdiTitle: string;
	rr20Title: string;
	rr30Title: string;
	rr50Title: string;
	cddTitle: string;
	gsl5Title: string;
	gsl10Title: string;
	speiTitle: string;
	htcTitle: string;
	eqTitle: string;
	faiTitle: string;
	hwfiTitle: string;
	rx1dTitle: string;
	rx5dTitle: string;
	suTitle: string;
	txge35Title: string;
	hwfidTitle: string;
	hwdiTitle: string;
	hwdidTitle: string;
	cwdTitle: string;
	spei6aTitle: string;
}

interface PeriodBase extends Title, Info, TitleShort {}

interface PeriodChange extends Title, Info, TitleShort {}

interface Scenario extends Title, Info, TitleShort {
	rcp45: string;
	rcp85: string;
	'SRES-A1B': string;
}

interface Period extends Title, Info {
	base: PeriodBase;
	change: PeriodChange;
	scenario: Scenario;
}

interface Season extends Title, Info {
	god: string;
	djf: string;
	mam: string;
	jja: string;
	son: string;

	godSeason: string;
	djfSeason: string;
	mamSeason: string;
	jjaSeason: string;
	sonSeason: string;

	godTooltip: string;
	djfTooltip: string;
	mamTooltip: string;
	jjaTooltip: string;
	sonTooltip: string;
}

interface Area extends Title, Info {
	// If you are renaming any of params, also rename Area enum
	serbiaSummary: string;
	regionsSummary: string;
	townshipsSummary: string;
	pointSummary: string;

	serbia: string;
	regions: string;
	townships: string;
	point: string;

	serbiaTooltip: string;
	regionsTooltip: string;
	townshipsTooltip: string;
	pointTooltip: string;

	serbiaSearchPlaceholder: string;
	regionsSearchPlaceholder: string;
	townshipsSearchPlaceholder: string;
	pointSearchPlaceholder: string;
}

interface FiltersLocal {
	dataVisualisation: FilterDataVisualisation;
	variable: VariableLocal;
	period: Period;
	heating: Title;
	season: Season;
	area: Area;
}

interface DownloadOptions {
	image: string;
	pdf: string;
	file: string;
}

interface Options {
	download: DownloadOptions;
}

interface Legend {
	min: string;
	max: string;
}

interface MapChart {
	forPeriod: string;
	deviationForPeriod: string;
	relativeTo: string;
	forScenario: string;
}

interface MapDetails {
	tabSeries: string;
	tabSummary: string;

	deviation: string;
	value: string;
	period: string;

	ens: string;
	ens75: string;
	ens25: string;
	basic: string;

	chart: MapChart;
}

interface MapTour extends Title, Info {
	map: string;

	observation: string;
	projection: string;

	variables: string;
	variablesFilter: string;

	period: string;
	periodFilter: string;

	season: string;
	seasonFilter: string;

	area: string;
	areaFilter: string;
	areaSearch: string;
	areaSearchLonLat: string;

	legend: string;
	zoomIn: string;
	zoomOut: string;
	pin: string;
	download: string;
}

interface Tour {
	closeButton: string;
	backButton: string;
	nextButton: string;
	completeButton: string;
	startButton: string;

	map: MapTour;
}

interface RawFilesData extends Title, Info {}

interface RawFilesSeason extends Title, Info {
	d: string; // day
	m: string; // month
	g: string; // year
}

interface RawFilesVariables extends Title, Info {}

interface RawFilesScenario extends Title, Info {}

interface RawFilesFilters extends Title, Info {
	data: RawFilesData;
	season: RawFilesSeason;
	variables: RawFilesVariables;
	scenario: RawFilesScenario;
}

interface RawFiles extends Title, Info {
	ebupom: string;
	eobs: string;
	models: string;

	filters: RawFilesFilters;

	viz: string;
	basePeriod: string;

	// scenario
	rcp45: string;
	rcp85: string;
	'sres-a1b': string;

	// model
	eobsFile: string;
	modelensFile: string;
	ebupomFile: string;

	noResultsTitle: string;
	noResultsMessage: string;

	applyFilters: string;
}

interface Table extends Title, Info {
	headers: Array<string | string[]>;
	rows: Array<string[] | Array<string[]>>;
}

interface List extends Title, Info {
	items: string[];
}

interface QuestionAnswer {
	question: string;
	answer: string;

	info?: string;
	images?: string[];
	table?: Table;
	tableList?: Table[];
	list?: List;
}

interface IndexDefinition {
	// name will be taken from filter.variables.<variable>Title
	definition: string;
	usage: string;

	images?: string[];
	infoList?: string[];
}

interface QAndA extends Title, Info {
	// slot for info
	portalUrl: string;

	// slots
	climateTerms: string;
	climateModels: string;
	worldMeteorologicalOrganization: string;
	wmo: string;
	mostCommonUsed: string;
	periodBase: string;
	periodChanges: string;

	q5AnswerSlot1: string;
	q5AnswerSlot2: string;

	q6AnswerSlot1: string;
	q6AnswerSlot2: string;

	q7AnswerSlot1: string;
	q7AnswerSlot2: string;
	q7AnswerSlot3: string;

	q8AnswerSlot1: string;
	q8AnswerSlot2: string;

	q9AnswerSlot1: string;
	q9AnswerSlot2: string;
	q9AnswerSlot3: string;

	q15AnswerSlot1: string;

	q16AnswerSlot1: string;

	q18AnswerSlot2: string;

	question1: QuestionAnswer;
	question2: QuestionAnswer;
	question3: QuestionAnswer;
	question4: QuestionAnswer;
	question5: QuestionAnswer;
	question6: QuestionAnswer;
	question7: QuestionAnswer;
	question8: QuestionAnswer;
	question9: QuestionAnswer;
	question10: QuestionAnswer;
	question11: QuestionAnswer;
	question12: QuestionAnswer;
	question13: QuestionAnswer;
	question14: QuestionAnswer;
	question15: QuestionAnswer;
	question16: QuestionAnswer;
	question17: QuestionAnswer;
	question18: QuestionAnswer;
	question19: QuestionAnswer;
	question20: QuestionAnswer;
	question21: QuestionAnswer;
	question22: QuestionAnswer;
	question23: QuestionAnswer;
	question24: QuestionAnswer;
	question25: QuestionAnswer;
}

interface ClimateIndexes extends Title, Info {
	// slots:
	doiLink: string;

	index1: IndexDefinition;
	index2: IndexDefinition;
	index3: IndexDefinition;
	index4: IndexDefinition;
	index5: IndexDefinition;
	index6: IndexDefinition;
	index7: IndexDefinition;
	index8: IndexDefinition;
	index9: IndexDefinition;
	index10: IndexDefinition;
	index11: IndexDefinition;
	index12: IndexDefinition;
	index13: IndexDefinition;
	index14: IndexDefinition;
	index15: IndexDefinition;
	index16: IndexDefinition;
	index17: IndexDefinition;
	index18: IndexDefinition;
	index19: IndexDefinition;
	index20: IndexDefinition;
	index21: IndexDefinition;
	index22: IndexDefinition;
	index23: IndexDefinition;
}

export interface PrefixDefinition {
	prefix: string;
	separator?: string;
	definition: string;
}

export interface InfoObject extends Title, Info {
	tableList?: Table[];
	imageList?: string[];
	infoList?: string[];
	definitionList?: PrefixDefinition[];
}

export interface FileNames extends Title, Info {
	info1: InfoObject;
	info2: InfoObject;
	info3: InfoObject;
	info4: InfoObject;
	info5: InfoObject;
}

export interface Citation extends Title, Info {
	infoList?: string[];

	// slots
	platformUrl: string;
	uerra: string;
	ecad: string;
	colorBrewer: string;
}

interface FAQ extends Title, Info {
	qAndA: QAndA;
	climateIndexes: ClimateIndexes;
	fileNames: FileNames;
	citation: Citation;
}

interface Contact extends Title, Info {}

interface AdaptationPlanning extends Title, Info {
	infoList: List;
	list: List;
}

interface ClimateAtlas extends Title, Info {
	infoList: List;
	list: List;

	cordex: string;
	climateCopernicus: string;
	geoSerbia: string;
	neopix: string;
}

interface About extends Title, Info {
	adaptationPlanning: AdaptationPlanning;
	climateAtlas: ClimateAtlas;
}

interface Unit {
	Celsius: string;
	'%': string;
	mm: string;
	spei: string;
	fai: {
		index: string;
	};
	eq: {
		index: string;
	};
	htc: {
		index: string;
	};
	No: {
		HW: string;
		WP: string;
		days: string;
	};
	Dur: {
		HW: string;
		WP: string;
	};
}

export default class MyLocale implements Title {
	public title: string;

	public nav: Nav;
	public tab: Tab;
	public filters: FiltersLocal;
	public options: Options;
	public legend: Legend;
	public mapDetails: MapDetails;
	public word: Word;
	public message: Message;
	public tour: Tour;
	public rawFiles: RawFiles;
	public faq: FAQ;
	public contact: Contact;
	public about: About;
	public unit: Unit;

	constructor(obj: MyLocale) {
		this.title = obj.title;

		this.nav = obj.nav;
		this.tab = obj.tab;
		this.filters = obj.filters;
		this.options = obj.options;
		this.legend = obj.legend;
		this.mapDetails = obj.mapDetails;
		this.word = obj.word;
		this.message = obj.message;
		this.tour = obj.tour;
		this.rawFiles = obj.rawFiles;
		this.faq = obj.faq;
		this.contact = obj.contact;
		this.about = obj.about;
		this.unit = obj.unit;
	}
}
