import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "static-info" }
const _hoisted_2 = { class: "static-title" }
const _hoisted_3 = { class: "static-subtitle" }
const _hoisted_4 = { class: "static-text" }
const _hoisted_5 = { class: "static-label mb-4 mt-16" }
const _hoisted_6 = { class: "static-label mb-4 mt-16" }
const _hoisted_7 = { class: "static-text" }
const _hoisted_8 = { class: "static-subtitle" }
const _hoisted_9 = { class: "static-label mb-4 mt-16" }
const _hoisted_10 = {
  href: "https://www.uerra.eu",
  target: "_blank"
}
const _hoisted_11 = {
  href: "https://www.ecad.eu",
  target: "_blank"
}
const _hoisted_12 = { class: "static-label mb-4 mt-16" }
const _hoisted_13 = { class: "static-text" }
const _hoisted_14 = { class: "static-label mb-4 mt-16" }
const _hoisted_15 = {
  href: "http://www.ColorBrewer.org",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('faq.citation.title')), 1),
    _createVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('faq.citation.infoList[0]')), 1),
    _createVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('faq.citation.infoList[1]')), 1),
    _createVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t('faq.citation.infoList[2]')), 1),
    _createVNode(_component_i18n_t, {
      class: "static-text",
      keypath: "faq.citation.infoList[3]",
      tag: "p"
    }, {
      platformUrl: _withCtx(() => [
        _createVNode("a", {
          href: _ctx.$t('faq.citation.platformUrl'),
          target: "_blank"
        }, _toDisplayString(_ctx.$t('faq.citation.platformUrl')), 9, ["href"])
      ]),
      _: 1
    }),
    _createVNode("h4", _hoisted_6, _toDisplayString(_ctx.$t('faq.citation.infoList[4]')), 1),
    _createVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('faq.citation.infoList[5]')), 1),
    _createVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('faq.citation.infoList[6]')), 1),
    _createVNode("h4", _hoisted_9, _toDisplayString(_ctx.$t('faq.citation.infoList[7]')), 1),
    _createVNode(_component_i18n_t, {
      class: "static-text",
      keypath: "faq.citation.infoList[8]",
      tag: "p"
    }, {
      uerra: _withCtx(() => [
        _createVNode("a", _hoisted_10, _toDisplayString(_ctx.$t('faq.citation.uerra')), 1)
      ]),
      ecad: _withCtx(() => [
        _createVNode("a", _hoisted_11, _toDisplayString(_ctx.$t('faq.citation.ecad')), 1)
      ]),
      _: 1
    }),
    _createVNode("h4", _hoisted_12, _toDisplayString(_ctx.$t('faq.citation.infoList[9]')), 1),
    _createVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('faq.citation.infoList[10]')), 1),
    _createVNode("h4", _hoisted_14, _toDisplayString(_ctx.$t('faq.citation.infoList[11]')), 1),
    _createVNode(_component_i18n_t, {
      class: "static-text",
      keypath: "faq.citation.infoList[12]",
      tag: "p"
    }, {
      colorBrewer: _withCtx(() => [
        _createVNode("a", _hoisted_15, _toDisplayString(_ctx.$t('faq.citation.colorBrewer')), 1)
      ]),
      _: 1
    })
  ]))
}