
import { defineComponent, PropType } from 'vue';
import { ButtonType } from '@/enums/ButtonType.enum';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CButton',
	props: {
		/**
		 * Type of button - will define css class for button in normal & active state
		 *
		 * class for
		 *  - normal state: `c-button-${type}` (eg. c-button-filter)
		 *  - active state: `c-button-${type}--active` (eg. c-button-filter--active)
		 *
		 *  If you are adding new type, add it in ButtonType enum,
		 *  then add proper style for normal & active state in this component.
		 */
		type: {
			required: false,
			type: String as PropType<ButtonType>,
			default: () => ButtonType.PRIMARY,
			validator: (value: ButtonType) => Object.values(ButtonType).includes(value),
		},
		elementType: {
			required: false,
			type: String,
			default: 'button',
		},
		/**
		 *  Is button active - will add proper active class for current type
		 *  eg.
		 *  type: 'filter'
		 *  active state class: 'c-button-filter--active'
		 */
		isActive: {
			required: false,
			type: Boolean,
			default: false,
		},
		title: {
			required: false,
			type: String,
			default: '',
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false,
		},
		disabledTitle: {
			required: false,
			type: String,
			default: '',
		},
	},
	computed: {
		activeClass(): string {
			return `c-button-${this.type}--active`;
		},
		disabledClass(): string {
			return `c-button-${this.type}--disabled`;
		},
	},
});
