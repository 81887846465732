import { RouteRecordRaw, RouterView } from 'vue-router';
import { RouteName } from '@/enums/RouteName.enum';
import { RoutePath } from '@/enums/RoutePath.eunm';
import { InfoTabPath } from '@/enums/InfoTabPath.enum';
import { InfoTabName } from '@/enums/InfoTabName.enum';
import InfoQAndA from '@/components/info/InfoQAndA.vue';
import InfoClimateIndexes from '@/components/info/InfoClimateIndexes.vue';
import InfoFileNames from '@/components/info/InfoFileNames.vue';
import InfoCitingData from '@/components/info/InfoCitingData.vue';
import { t } from '@/utils/utils';

export const routes: Array<RouteRecordRaw> = [
	{
		path: `/:lang/${RoutePath.MAP}`,
		name: RouteName.MAP,
		component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
		props: (route) => ({ params: route.query }),
		meta: {
			title: t('nav.map'),
		},
	},
	{
		path: `/:lang/${RoutePath.ABOUT}`,
		name: RouteName.ABOUT,
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
		meta: {
			title: t('nav.about'),
		},
	},
	{
		path: `/:lang/${RoutePath.RAW_FILES}`,
		name: RouteName.RAW_FILES,
		component: () => import(/* webpackChunkName: "raw-files" */ '../views/RawFiles.vue'),
		meta: {
			title: t('nav.rawFiles'),
		},
	},
	{
		path: `/:lang/${RoutePath.CONTACT}`,
		name: RouteName.CONTACT,
		component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
		meta: {
			title: t('nav.contact'),
		},
	},
	{
		path: `/:lang/${RoutePath.INFO}`,
		name: RouteName.INFO,
		component: () => import(/* webpackChunkName: "info" */ '../views/Info.vue'),
		redirect: { name: InfoTabName.Q_AND_A },
		meta: {
			title: t('nav.info'),
		},
		children: [
			{
				path: InfoTabPath.Q_AND_A,
				name: InfoTabName.Q_AND_A,
				component: InfoQAndA,
				meta: {
					title: t('tab.info.qAndA'),
				},
			},
			{
				path: InfoTabPath.CLIMATE_INDEXES,
				name: InfoTabName.CLIMATE_INDEXES,
				component: InfoClimateIndexes,
				meta: {
					title: t('tab.info.climateIndexes'),
				},
			},
			{
				path: InfoTabPath.FILE_NAMES,
				name: InfoTabName.FILE_NAMES,
				component: InfoFileNames,
				meta: {
					title: t('tab.info.fileNames'),
				},
			},
			{
				path: InfoTabPath.CITING_DATA,
				name: InfoTabName.CITING_DATA,
				component: InfoCitingData,
				meta: {
					title: t('tab.info.citingData'),
				},
			},
		],
	},
];
