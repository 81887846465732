import MyLocale from '@/models/MyLocale.model';
import { createI18n } from 'vue-i18n';
import { LocaleEnum } from '@/enums/Locale.enum';
import { srLatin } from '@/localization/lat.locale';
import { srCyrillic } from '@/localization/cir.locale';
import { en } from '@/localization/eng.locale';

const messages: Record<string, MyLocale> = {
	[LocaleEnum.ENG]: en,
	[LocaleEnum.SRB_LATIN]: srLatin,
	[LocaleEnum.SRB_CYRILLIC]: srCyrillic,
};

export const DEFAULT_LOCAL = process.env.VUE_APP_DEFAULT_LOCAL;

export const i18n = createI18n({
	locale: DEFAULT_LOCAL,
	fallbackLocale: LocaleEnum.SRB_LATIN,
  warnHtmlInMessage: 'off', // disable of the Detected HTML in message
	messages: messages as any,
});
