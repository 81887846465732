import { LocaleEnum } from '@/enums/Locale.enum';

export class LocaleOption {
	public locale: LocaleEnum;
	public name: string;
	public shortName: string;

	constructor(obj: LocaleOption) {
		this.locale = obj.locale;
		this.name = obj.name;
		this.shortName = obj.shortName;
	}
}
