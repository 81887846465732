
import { defineComponent } from 'vue';
import CCollapse from '@/components/shared/CCollapse.vue';
import { getImageFormAssets, range, scrollIntoView, t } from '@/utils/utils';
import { RoutePath } from '@/enums/RoutePath.eunm';
import { InfoTabName } from '@/enums/InfoTabName.enum';
import { QuestionId } from '@/enums/QuestionId.enum';
import { useMeta } from 'vue-meta';

enum FileNamesId {
	FN1 = 'file-names-1',
	FN2 = 'file-names-2',
	FN3 = 'file-names-3',
	FN4 = 'file-names-4',
	FN5 = 'file-names-5',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'InfoFileNames',
	components: { CCollapse },
	setup() {
		useMeta({
			title: t('tab.info.fileNames'),
		})
		return {
			FileNamesId,
			getImageFormAssets,
			range,
		};
	},
	computed: {
		currentFileNameId(): string {
			return this.$route.hash.substring(1);
		},
		regionsShorthandUrl(): string {
			const { lang } = this.$route.params;
			return `/${lang}/${RoutePath.INFO}/${InfoTabName.Q_AND_A}#${QuestionId.Q25}`;
		},
	},
	mounted() {
		if (this.currentFileNameId) {
			scrollIntoView(`#${this.currentFileNameId}`);
		}
	},
	methods: {
		setFileNameId(id: FileNamesId) {
			window.history.pushState({}, null, `${this.$route.path}#${id}`);
		},
		removeFileNameId() {
			window.history.pushState({}, null, this.$route.path);
		},
		handleFileNameToggle(id: FileNamesId, isOpen: boolean): void {
			if (isOpen) {
				this.setFileNameId(id);
			} else {
				this.removeFileNameId();
			}
		},
		toOpenFileName(id: FileNamesId): boolean {
			return this.currentFileNameId === id;
		},
	},
});
