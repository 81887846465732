export class CButtonRadioModel {
	public name: string;
	public value: string;
	public checked?: boolean;
	public label?: string;

	constructor(obj: CButtonRadioModel) {
		this.name = obj.name;
		this.value = obj.value;
		this.checked = obj.checked;
		this.label = obj.label;
	}
}
