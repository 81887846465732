
import { defineComponent, PropType } from 'vue';
import { LinkType } from '@/enums/LinkType.enum';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CLink',
	props: {
		/**
		 *  Defines where is a user going to.
		 *  And it will generate link (proper href)
		 *  based on path or object that contains name & param(if needed)
		 *
		 *  For more info see
		 *  @see {@link https://next.router.vuejs.org/guide/#router-link}
		 */
		to: {
			required: true,
			type: [String, Object] as PropType<string | Record<string,string>>,
		},
		/**
		 * This prop represents name of path where is users going to.
		 * It is used so component will know when the link is active.
		 *
		 * You can find name of path in const routes in app-routes.ts
		 */
		name: {
			required: true,
			type: String,
		},
		type: {
			required: false,
			type: String as PropType<LinkType>,
			default: () => LinkType.NAV,
			validator: (value: LinkType) => Object.values(LinkType).includes(value),
		},
	},
	computed: {
		isRouteActive(): boolean {
			return this.$route.matched.map(route => route.name).includes(this.name as string);
		},
		typeClass(): string {
			return `c-link-${this.type}`;
		},
		activeClass(): string {
			return `${this.typeClass}--active`;
		},
	},
});
