
import { defineComponent } from 'vue';
import { t } from '@/utils/utils';
import { useMeta } from 'vue-meta';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'InfoCitingData',
	setup() {
		useMeta({
			title: t('tab.info.citingData'),
		})
	},
});
