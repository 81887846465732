
import { defineComponent, ref, watch } from 'vue';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import CIconUpDown from '@/components/shared/CIconUpDown.vue';

enum ComponentEvent {
	CHANGE_VISIBILITY = 'changeVisibility',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CCollapse',
	components: { CIconUpDown },
	props: {
		title: {
			required: true,
			type: String,
		},
		titleInfo: {
			required: false,
			type: String,
			default: () => '',
		},
		isOpen: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		isDisabled: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		disabledTitle: {
			required: false,
			type: String,
			default: () => '',
		},
		/**
		 *  If set to false, element will be collapsable on whole header.
		 */
		collapseOnlyOnIcon: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		errorMsg: {
			required: false,
			type: String,
			default: () => '',
		},
	},
	setup(props, context) {
		const disabled = ref<boolean>(props.isDisabled);
		const visibility = ref<boolean>(props.isOpen && !props.isDisabled);

		const isOpenSource = () => props.isOpen;
		const isDisabledSource = () => props.isDisabled;

		watch([isOpenSource, isDisabledSource], ([isOpen, isDisabled]) => {
			disabled.value = isDisabled;
			visibility.value = isOpen && !isDisabled;
		});

		function toggleVisibility(): void {
			if (disabled.value) {
				return;
			}
			visibility.value = !visibility.value;
			context.emit(ComponentEvent.CHANGE_VISIBILITY, visibility.value);
		}

		return {
			visibility,
			disabled,
			toggleVisibility,
			faChevronDown,
		};
	},
});
